import * as _ from "lodash";
import * as utils from "../utils";

// string comparisions
const strcontains = (valuetocompare, valuecompareto)=>{
    let res = valuecompareto.includes(valuetocompare);
    return res;
}

const strdoesnotcontains = (valuetocompare, valuecompareto)=>{
    let res = valuecompareto.includes(valuetocompare);
    return !res;
}

const strisin = (valuetocompare, valuecompareto)=>{
    let res = valuetocompare.includes(valuecompareto);
    return res;
}

const strnotisin  = (valuetocompare, valuecompareto)=>{
    let res = valuetocompare.includes(valuecompareto);
    return !res;
}

const strstartwith = (valuetocompare, valuecompareto)=>{
    let res = _.startsWith(valuetocompare, valuecompareto);
    return res;
}

const strdoesnotstartwith = (valuetocompare, valuecompareto)=>{
    let res = _.startsWith(valuetocompare, valuecompareto);
    return !res;
}

const strendswith = (valuetocompare, valuecompareto)=>{
    let res = _.endsWith(valuetocompare, valuecompareto);
    return res;
}

const strdoesnotendswith = (valuetocompare, valuecompareto)=>{
    let res = _.endsWith(valuetocompare, valuecompareto);
    return !res;
}

// number and datetime comparisions
const lessthan = (valuetocompare, valuecompareto)=>{
    let res = (valuecompareto<valuetocompare);
    return res;
}

const lessthanorequal = (valuetocompare, valuecompareto)=>{
    let res = (valuecompareto<=valuetocompare);
    return res;
}

const greaterthan = (valuetocompare, valuecompareto)=>{
    let res = (valuecompareto>valuetocompare);
    return res;
}

const greaterthanorequal = (valuetocompare, valuecompareto)=>{
    let res = (valuetocompare>=valuecompareto);
    return res;
}

// arrays
const isin = (valuetocompare, valuecompareto)=>{
    let res = _.findIndex(valuecompareto, (v)=>{return v==valuetocompare});
    if(res>-1){
        return true;
    }else{
        return false;
    }
}

const isnotin = (valuetocompare, valuecompareto)=>{
    let res = _.findIndex(valuecompareto, (v)=>{return v==valuetocompare});
    if(res>-1){
        return true;
    }else{
        return false;
    }
}

const contains = (valuetocompare, valuecompareto)=>{
    let res = _.findIndex(valuetocompare, (v)=>{return v==valuecompareto});
    if(res>-1){
        return false;
    }else{
        return true;
    }
}

const doesnotcontains = (valuetocompare, valuecompareto)=>{
    let res = _.findIndex(valuetocompare, (v)=>{return v==valuecompareto});
    if(res>-1){
        return true;
    }else{
        return false;
    }
}

//object
const hasKey = (valuetocompare, valuecompareto)=>{
    let res = _.hasIn(valuecompareto, valuetocompare);
    return res;
}

const doesnothaskey = (valuetocompare, valuecompareto)=>{
    let res = _.hasIn(valuecompareto, valuetocompare);
    return res;
}

// generic compare statements
const equals = (valuetocompare, valuecompareto)=>{
    let res = (valuecompareto==valuetocompare);
    return res;
}

const doesnotequals = (valuetocompare, valuecompareto)=>{
    let res = (valuecompareto==valuetocompare);
    return !res;
}

const exists  = ()=>{

}

const doesnotexists = ()=>{

}


export const executeElementConditions = (condition, valuetocompare, valuecompareto)=>{
    let res;
    if(condition=="eq"){
        res = equals(valuetocompare, valuecompareto);
    }else if(condition=="neq"){
        res = doesnotequals(valuetocompare, valuecompareto)
    }else if(condition=="strcontains"){
        res = strcontains(valuetocompare, valuecompareto);
    }else if(condition=="strdoes_not_contains"){
        res = strdoesnotcontains(valuetocompare, valuecompareto);
    }else if(condition=="str_is_in"){
        res = strisin(valuetocompare, valuecompareto)
    }else if(condition=="str_is_not_in"){
        res = strnotisin(valuetocompare, valuecompareto)
    }else if(condition=="str_starts_with"){
        res = strstartwith(valuetocompare, valuecompareto)
    }else if(condition=="str_does_not_starts_with"){
        res = strdoesnotstartwith(valuetocompare, valuecompareto);
    }else if(condition=="str_ends_with"){
        res = strendswith(valuetocompare, valuecompareto);
    }else if(condition=="str_does_not_ends_with"){
        res = strdoesnotendswith(valuetocompare, valuecompareto);
    }else if(condition=="str_does_not_ends_with"){
        res = strdoesnotstartwith(valuetocompare, valuecompareto);
    }else if(condition=="exists"){
        res = exists(valuetocompare, valuecompareto)
    }else if(condition=="lt"){
        res = lessthan(valuetocompare, valuecompareto)
    }else if(condition=="lte"){
        res = lessthanorequal(valuetocompare, valuecompareto)
    }else if(condition=="gt"){
        res = greaterthan(valuetocompare, valuecompareto)
    }else if(condition=="gte"){
        res  = greaterthanorequal(valuetocompare, valuecompareto);
    }else if(condition=="is_in"){
        res  = isin(valuetocompare, valuecompareto);
    }else if(condition=="isnotin"){
        res = isnotin(valuetocompare, valuecompareto);
    }else if(condition=="contains"){
        res = contains(valuetocompare, valuecompareto)
    }else if(condition=="does_not_contains"){
        res = doesnotcontains(valuetocompare, valuecompareto)
    }else if(condition=="has_key"){
        res = hasKey(valuetocompare, valuecompareto)
    }else if(condition=="does_not_has_key"){
        res = doesnothaskey(valuetocompare, valuecompareto)
    }
    return res;
}

export const executeAction = async (stage,pipeline)=>{
    // let stageconditions = stage.conditions;
    let proceed = false;
    for(let i=0; i< stage.conditions.length; i++){
        let blockproceed = true;
        for(let j=0; j<stage.conditions[i].length;j++){
            let valuetocomparemapping = {...stage.valuetocompare};
            let valuetocompare;
            if(valuetocomparemapping.action=="const"){
                valuetocompare = valuetocomparemapping.val;
            }
            if(valuetocomparemapping.action=="get"){
                valuetocompare =await utils.getValfromPipe(valuetocomparemapping.val,pipeline);
            }
            let valuecomparetomapping = stage.conditions[i][j].valuecompareto;
            let valuecompareto;
            if(valuecomparetomapping.action=="const"){
                valuecompareto = valuecomparetomapping.val;
            }
            if(valuecomparetomapping.action=="get"){
                valuecompareto = await utils.getValfromPipe(valuecomparetomapping.val,pipeline);
            }
            let res;
            if(stage.conditions[i][j].condition=="eq"){
                res = equals(valuetocompare, valuecompareto);
            }else if(stage.conditions[i][j].condition=="neq"){
                res = doesnotequals(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="strcontains"){
                res = strcontains(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="strdoes_not_contains"){
                res = strdoesnotcontains(valuetocompare, valuecompareto);
            }else if(stage.conditions[i][j].condition=="str_is_in"){
                res = strisin(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="str_is_not_in"){
                res = strnotisin(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="str_starts_with"){
                res = strstartwith(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="str_does_not_starts_with"){
                res = strdoesnotstartwith(valuetocompare, valuecompareto);
            }else if(stage.conditions[i][j].condition=="str_ends_with"){
                res = strendswith(valuetocompare, valuecompareto);
            }else if(stage.conditions[i][j].condition=="str_does_not_ends_with"){
                res = strdoesnotendswith(valuetocompare, valuecompareto);
            }else if(stage.conditions[i][j].condition=="str_does_not_ends_with"){
                res = strdoesnotstartwith(valuetocompare, valuecompareto);
            }else if(stage.conditions[i][j].condition=="exists"){
                res = exists(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="lt"){
                res = lessthan(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="lte"){
                res = lessthanorequal(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="gt"){
                res = greaterthan(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="gte"){
                res  = greaterthanorequal(valuetocompare, valuecompareto);
            }else if(stage.conditions[i][j].condition=="is_in"){
                res  = isin(valuetocompare, valuecompareto);
            }else if(stage.conditions[i][j].condition=="isnotin"){
                res = isnotin(valuetocompare, valuecompareto);
            }else if(stage.conditions[i][j].condition=="contains"){
                res = contains(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="does_not_contains"){
                res = doesnotcontains(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="has_key"){
                res = hasKey(valuetocompare, valuecompareto)
            }else if(stage.conditions[i][j].condition=="does_not_has_key"){
                res = doesnothaskey(valuetocompare, valuecompareto)
            }
            blockproceed = blockproceed&&res;
            // let valuetocompare = utils.getValfromPipe(stageconditions[i].valuetocompare,)
        }
        proceed = blockproceed||proceed;
    }
    return {result:proceed};
}