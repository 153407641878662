import { useDispatch, useSelector } from "react-redux";
import ElementContainer from "../ElementContainer";
import { 
        jsoncompare, 
        selectInnerHtml, 
        selectElementStyles, 
        selectProps, 
        selectActivePosition, 
        selectElementClasses, 
        selectFocusPosition, 
        setActivePosition,
        setFocusPosition,
        selectFocusKey,
        selectActiveKey
    } from "../../../Services/apptemplates/apptemplates.slice";
import { getInteracionRunner } from "../utils";
import InputContainers from "./InputContainers";
import { useParams } from "react-router-dom";
import RouterContainer from "./RouterContainer";
import { useEffect, useRef, useState } from "react";
import "./HtmlNativeElement.css";
import * as _ from "lodash";


const HtmlNativeElements = ({
                              element,
                              elmkey,
                              indexarr,
                              position,
                              type,
                              childs,
                              parentkey,
                              parentindexarr,
                              parentposition,
                              templateinteractions
                            })=>{

    // const [cls, setcls] = useState("");

    const [elmActive, setElmActive] = useState(false);

    const [elmFocus, setElmFocus] = useState(false);

    const routeParams = useParams();

    const ref = useRef(null);
    
    const innerhtml = useSelector((state)=>
                                    selectInnerHtml(
                                                    state,
                                                    {schema: element.innerhtml, schemamapping: element.innerhtmlmapping, key:elmkey},
                                                    routeParams,
                                                    elmkey,
                                                    position
                                                    ), jsoncompare);

    const dispatch = useDispatch();

    const generateProps = ()=>{
        let props = {};
    }

    const styles = useSelector((state)=>{return selectElementStyles(state, element.styles, elmkey, position)}, jsoncompare)

    const clsString = useSelector((state)=>{return selectElementClasses(state, element.scusers, element.scsystems, elmkey, position)})

    const props = useSelector((state)=>{return selectProps(
                                                            state, 
                                                            {schema: element.props, schemamapping: element.propsmapping},
                                                            elmkey,
                                                            position
                                                            )}, jsoncompare )
    
    const getInteractions = ()=>{
        let interactions = {};
        for(let i=0; i< element.interactions.length; i++){
            if(
                element.interactions[i].name!=""&&
                element.interactions[i].name!=undefined&&
                element.interactions[i].pipeline!=""&&
                element.interactions[i].pipeline!=undefined
            ){
                if(interactions[element.interactions[i].name]!=undefined){
                    interactions[element.interactions[i].name].interactions.push(
                        {
                            key: elmkey,
                            pipelineid: element.interactions[i].pipeline,
                            schemamapping: element.interactions[i].schemamapping,
                            position: position
                         }
                    )
                }else{
                    interactions[element.interactions[i].name] = {
                                                                    name: element.interactions[i].name,
                                                                    interactions: []
                                                                  };
                    interactions[element.interactions[i].name].interactions.push(
                        {
                            key: elmkey,
                            pipelineid: element.interactions[i].pipeline,
                            schemamapping: element.interactions[i].schemamapping,
                            position: position
                         }
                    )
                }
            }
                        
        }
        if(templateinteractions){
            for(let i=0; i < templateinteractions.length; i++){
                if(
                    templateinteractions[i].name!=""&&
                    templateinteractions[i].name!=undefined&&
                    templateinteractions[i].pipeline!=""&&
                    templateinteractions[i].pipeline!=undefined
                ){
                    if(templateinteractions[i].type=="browser"){
                        if(interactions[templateinteractions[i].name]!=undefined){
                            interactions[templateinteractions[i].name].push(
                                {
                                    key: parentkey,
                                    pipelineid: templateinteractions[i].pipeline,
                                    schemamapping: templateinteractions[i].schemamapping,
                                    position: parentposition
                                 }
                            )
                        }else{
                            interactions[templateinteractions[i].name] = {
                                                                            name: templateinteractions[i].name,
                                                                            interactions: []
                                                                         };
                            interactions[templateinteractions[i].name].push(
                                {
                                    key: parentkey,
                                    pipelineid: templateinteractions[i].pipeline,
                                    schemamapping: templateinteractions[i].schemamapping,
                                    position: parentposition
                                 }
                            )
                        }
                    }
                }
            }
        }
        
        let loadedinteractions = Object.keys(interactions);
        let runnableinteractions= {};
        for(let i=0; i< loadedinteractions.length; i++){
            let eventinteractions = interactions[loadedinteractions[i]].interactions;
            runnableinteractions[interactions[loadedinteractions[i]].name] = (e)=>{
                let interactionrunner = getInteracionRunner(eventinteractions, dispatch, e);
                interactionrunner();
            }
        }
        return runnableinteractions;
    }

    const [elementLoaded, setElementLoaded] = useState(false);

    const getExtraProps = ()=>{
        let extraprops = {};
        if(element.extraprops.length>0){
            for(let i=0; i<element.extraprops.length; i++){
                extraprops[element.extraprops[i].key] =element.extraprops[i].value 
            }
        }
        return extraprops;
    }

    return (
        <>
            {element.tag=="div"&&
                <div 
                    {...getInteractions()}
                    {...getExtraProps()}
                    {...props}
                    style={styles}
                    ref={ref}
                    className={
                      clsString
                    }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                        <ElementContainer
                                            elmkey={elmkey}
                                            element={elm}
                                            indexarr={[...indexarr, index]}
                                            position={[...position, index]}
                                            type={type}
                                            parentkey={parentkey}
                                            parentindexarr={parentindexarr}
                                            parentposition={parentposition}
                                        >
                                        </ElementContainer>
                                    )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </div>
            }
            {element.tag=="p"&&
                <p 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                        <ElementContainer
                                            elmkey = {elmkey}
                                            element = {elm}
                                            indexarr = {[...indexarr, index]}
                                            position = {[...position, index]}
                                            type={type}
                                            parentkey={parentkey}
                                            parentindexarr={parentindexarr}
                                            parentposition={parentposition}
                                        >
                                        </ElementContainer>
                                    )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </p>
            }
            {element.tag=="span"&&
                <span 
                    {...getInteractions()}
                    {...getExtraProps()}
                    style={styles}
                    {...props}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </span>
            }
            {element.tag=="a"&&
                <a 
                    {...getInteractions()}
                    {...getExtraProps()}
                    style={styles}
                    {...props}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                                
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </a>
            }
            {element.tag=="img"&&
                <img 
                    {...getInteractions()}
                    {...getExtraProps()}
                    {...props}
                    style={styles}
                    ref={ref}
                    className={clsString}
                />
            }
            {element.tag=="input"&&
                <InputContainers
                    elmkey={elmkey}
                    element={element}
                    indexarr={indexarr}
                    position = {position}
                    clsName={clsString}
                    interactions={getInteractions()}
                >
                </InputContainers>
            }
            {element.tag=="select"&&
                <InputContainers
                    elmkey={elmkey}
                    element={element}
                    indexarr={indexarr}
                    position={position}
                    clsName={clsString}
                    interactions={getInteractions()}
                >
                </InputContainers>
            }
            {element.tag=="option"&&
                <option 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    className={clsString}
                >
                    {innerhtml}
                </option>
            }
            {element.tag=="form"&&
                <form 
                    {...getInteractions()}
                    {...props}
                    style={styles}
                    ref={ref}
                    className={clsString}
                    {...getExtraProps()}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </form>
            }
            {element.tag=="button"&&
                <button 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </button>
            }
            {element.tag=="table"&&
                <table 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </table>
            }
            {element.tag=="thead"&&
                <thead 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </thead>
            }
            {element.tag=="tbody"&&
                <tbody 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                                
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </tbody>
            }
            {element.tag=="tr"&&
                <tr 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </tr>
            }
            {element.tag=="td"&&
                <td 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </td>
            }
            {element.tag=="svg"&&
                <svg 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </svg>
            }
            {element.tag=="path"&&
                <>
                    <path
                        {...getInteractions()}
                        {...props}
                        {...getExtraProps()}
                        style={styles}
                        ref={ref}
                        className={clsString}
                    >
                    </path>
                </>
            }
            {element.tag=="h1"&&
                <h1 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                                
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </h1>
            }
            {element.tag=="h2"&&
                <h2 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </h2>
            }
            {element.tag=="h3"&&
                <h3 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </h3>
            }
            {element.tag=="h4"&&
                <h4 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                    <ElementContainer
                                        elmkey={elmkey}                                    
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </h4>
            }
            {element.tag=="h5"&&
                <h5 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </h5>
            }
            {element.tag=="h6"&&
                <h6 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </h6>
            }
            {element.tag=="ol"&&
                <ol 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                <ElementContainer
                                    elmkey={elmkey}
                                    element={elm}
                                    indexarr={[...indexarr, index]}
                                    position = {[...position, index]}
                                    type={type}
                                    parentkey={parentkey}
                                    parentindexarr={parentindexarr}
                                    parentposition={parentposition}
                                >
                                </ElementContainer>)
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </ol>
            }
            {element.tag=="ul"&&
                <ul 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(<ElementContainer
                                            elmkey={elmkey}
                                            element={elm}
                                            indexarr={[...indexarr, index]}
                                            position = {[...position, index]}
                                            type={type}
                                            parentkey={parentkey}
                                            parentindexarr={parentindexarr}
                                            parentposition={parentposition}
                                        >
                                        </ElementContainer>)
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </ul>
            }
            {element.tag=="li"&&
                <li 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(<ElementContainer
                                            elmkey={elmkey}
                                            element={elm}
                                            indexarr={[...indexarr, index]}
                                            position = {[...position, index]}
                                            type={type}
                                            parentkey={parentkey}
                                            parentindexarr={parentindexarr}
                                            parentposition={parentposition}
                                        >
                                        </ElementContainer>)
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </li>
            }
            {element.tag=="pre"&&
                <pre 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(<ElementContainer
                                            elmkey={elmkey}
                                            element={elm}
                                            indexarr={[...indexarr, index]}
                                            position = {[...position, index]}
                                            type={type}
                                            parentkey={parentkey}
                                            parentindexarr={parentindexarr}
                                            parentposition={parentposition}
                                        >
                                        </ElementContainer>)
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </pre>
            }
            {element.tag=="iframe"&&
                <iframe 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={clsString}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                <ElementContainer
                                    elmkey={elmkey}
                                    element={elm}
                                    indexarr={[...indexarr, index]}
                                    position = {[...position, index]}
                                    type={type}
                                    parentkey={parentkey}
                                    parentindexarr={parentindexarr}
                                    parentposition={parentposition}
                                >
                                </ElementContainer>)
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </iframe>
            }
            {element.tag=="icon"&&
                <span 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    style={styles}
                    ref={ref}
                    className={"material-symbols-outlined "+clsString}
                >
                    {element.name}
                </span>
            }
            {element.tag=="router"&&
                <RouterContainer
                    element={element}
                    type={type}
                >

                </RouterContainer>
            }
        </>
    )

}


export default HtmlNativeElements;