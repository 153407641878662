const utils = require("./../utils");

let navigate = null;

let currentpath = null

export const initNavigator = (nav, path)=>{
    navigate = nav;
    currentpath = path;

}

const checktrailingslash = (currentpath)=>{
    if(currentpath.endsWith("/")){
        return true;
    }else{
        return false;
    }
}

const checkleadingslash = (path)=>{
    if(path.startWith("/")){
        return true;
    }else{
        return false;
    }
}

function isValidURL(str) {
    if(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(str)) {
         return true;
     } else {
         return false;
     }
}

export const executeAction = async (stage, pipeline, debug)=>{
    if(stage.action=="navigate"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);  
        if(!isValidURL(inputval.path)){
            if(debug){
                return {
                    "navigation": inputval.path
                }
            }else{
    
                if(currentpath!=null){
                    if(checkleadingslash(inputval.path)||checktrailingslash(currentpath)){
                        navigate(currentpath+inputval.path);
                    }else{
                        navigate(currentpath+"/"+inputval.path);
                    }
                    
                }else{
                    navigate(inputval.path);
                }
            }
        }else{
            window.location.href = inputval.path;
        }
        
          
    }
}