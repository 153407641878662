import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWebsiteApi } from "./App.service";

export const getWebsite = createAsyncThunk(
    "toiler/getwebsite",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWebsiteApi(payload);
            return res;
        }catch(error){
            throw error;
        }
    }
)


const AppSlice = createSlice({
    name: "toiler",
    initialState: {
        "website": undefined
    },
    reducers:{
        
    },
    extraReducers: (builder)=>{
        builder.addCase(getWebsite.fulfilled, (state, action)=>{
            state.website = action.payload;
        })
    }

})

export const selectWebsite = state => state.toiler.website

export default AppSlice.reducer;