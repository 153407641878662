import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import AppContainer from "../AppContainer";


const RouterContainer = ({
    element,
    type
})=>{


    const getRoutes = ()=>{
        if(element!=undefined&&element!=null){
            let routes = [];
            for(let i=0; i< element.routes.length; i++){
                let path = element.routes[i].path;
                for(let j=0; j< element.routes[i].params.length; j++){
                    if(path.endsWith("/")){
                        path = path+ ":"+element.routes[i].params[j]+"/"
                    }else{
                        path = path+ "/:"+element.routes[i].params[j]+"/"
                    }
                }
                if(element.routes[i].template!=""){
                    routes.push({
                        "path": path,
                        "templateid": element.routes[i].template
                    })
                }
            }
            return routes;
        }else{
            return [];
        }
    }

    return (
        <Routes>
            {getRoutes().map((route)=>{
                return (
                    <Route path={route.path} element={<AppContainer
                                                        templateid={route.templateid}
                                                        indexarr={[]}
                                                        elmkey={""}
                                                        type={type}
                                                      ></AppContainer>}>
                    </Route>
                )
            })}

        </Routes>
    )

}


export default RouterContainer;