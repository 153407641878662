const InteractionEvents = {
    "keyboardevents":[
        {"name": "onKeyDown", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema": [],
                "value": ""
            },
            {
                "key": "charCode",
                "label": "CharCode",
                "type": "number",
                "subschema": [],
                "value": ""
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": [],
                "value": ""
            },
            {
                "key": "key",
                "label": "Key",
                "type": "string",
                "subschema": [],
                "value": ""
            },
            {
                "key": "keyCode",
                "label": "KeyCode",
                "type": "number",
                "subschema": [],
                "value": ""
            },
            {
                "key": "locale",
                "label": "Locale",
                "type": "string",
                "subschema": [],
                "value": ""
            },
            {
                "key": "location",
                "label": "Location",
                "type": "number",
                "subschema": [],
                "value": ""
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema": [],
                "value": ""
            },
            {
                "key": "repeat",
                "label": "Repeat",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "which",
                "label": "Which",
                "type": "number",
                "subschema": []
            }
        ]},
        {"name": "onKeyPress", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "charCode",
                "label": "CharCode",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "key",
                "label": "Key",
                "type": "string",
                "subschema": []
            },
            {
                "key": "keyCode",
                "label": "KeyCode",
                "type": "number",
                "subschema": []
            },
            {
                "key": "locale",
                "label": "Locale",
                "type": "string",
                "subschema": []
            },
            {
                "key": "location",
                "label": "Location",
                "type": "number",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "repeat",
                "label": "Repeat",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "which",
                "label": "Which",
                "type": "number",
                "subschema": []
            }
    
        ]},
        {"name": "onKeyUp", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "charCode",
                "label": "CharCode",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "key",
                "label": "Key",
                "type": "string",
                "subschema": []
            },
            {
                "key": "keyCode",
                "label": "KeyCode",
                "type": "number",
                "subschema": []
            },
            {
                "key": "locale",
                "label": "Locale",
                "type": "string",
                "subschema": []
            },
            {
                "key": "location",
                "label": "Location",
                "type": "number",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "repeat",
                "label": "Repeat",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "which",
                "label": "Which",
                "type": "number",
                "subschema": []
            }
    
    
        ]},
    ],
    "mouseevents":[
        {"name": "onClick", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onDoubleClick", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onMouseDown", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onMouseEnter", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onMouseLeave", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onMouseMove", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onMouseOut", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onMouseOver", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onMouseUp", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onTouchCancel", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "changedTouches",
                "label": "ChangedTouches",
                "type": "array",
                "subschema":[
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "targetTouches",
                "label": "TargetTouches",
                "type": "array",
                "subschema": [
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            },
            {
                "key": "touches",
                "label": "Touches",
                "type": "array",
                "subschema": [
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            }
        ]},
        {"name": "onTouchEnd", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "changedTouches",
                "label": "ChangedTouches",
                "type": "array",
                "subschema":[
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "targetTouches",
                "label": "TargetTouches",
                "type": "array",
                "subschema": [
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            },
            {
                "key": "touches",
                "label": "Touches",
                "type": "array",
                "subschema": [
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            }
    
        ]},
        {"name": "onTouchMove", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "changedTouches",
                "label": "ChangedTouches",
                "type": "array",
                "subschema":[
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "targetTouches",
                "label": "TargetTouches",
                "type": "array",
                "subschema": [
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            },
            {
                "key": "touches",
                "label": "Touches",
                "type": "array",
                "subschema": [
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            }
        ]},
        {"name": "onTouchStart", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "changedTouches",
                "label": "ChangedTouches",
                "type": "array",
                "subschema":[
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "targetTouches",
                "label": "TargetTouches",
                "type": "array",
                "subschema": [
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            },
            {
                "key": "touches",
                "label": "Touches",
                "type": "array",
                "subschema": [
                    {
                        "type": "object",
                        "subschema":[]
                    }
                ]
            }
        ]},
        {"name": "onScroll", "properties": [
            {
                "key": "detail",
                "label": "Detail",
                "type": "number",
                "subschema": []
            },
            {
                "key": "view",
                "label": "View",
                "type": "object",
                "subschema": []
            }
        ]},
        {"name": "onWheel", "properties": [
            {
                "key": "deltaMode",
                "label": "DeltaMode",
                "type": "number",
                "subschema": []
            },
            {
                "key": "deltaX",
                "label": "DeltaX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "deltaY",
                "label": "DeltaY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "deltaZ",
                "label": "DeltaZ",
                "type": "number",
                "subschema": []
            }
        ]}
    ],
    "formevents":[
        {"name": "onFocus", "properties":[]},
        {"name": "onBlur", "properties":[]},
    ],
    "dragevents":[
        {"name": "onDrag", "properties": [
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onDragEnd", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onDragEnter", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onDragExit", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onDragLeave", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onDragOver", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onDragStart", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]},
        {"name": "onDrop", "properties":[
            {
                "key": "altKey",
                "label": "AltKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "button",
                "label": "Button",
                "type": "number",
                "subschema": []
            },
            {
                "key": "buttons",
                "label": "Buttons",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientX",
                "label": "ClientX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "clientY",
                "label": "ClientY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "ctrlKey",
                "label": "CtrlKey",
                "type": "boolean",
                "subschema": []
            },
            {
                "key": "metaKey",
                "label": "MetaKey",
                "type": "boolean",
                "subschema":[]
            },
            {
                "key": "pageX",
                "label": "PageX",
                "type": "number",
                "subschema":[]
            },
            {
                "key": "pageY",
                "label": "PageY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenX",
                "label": "ScreenX",
                "type": "number",
                "subschema": []
            },
            {
                "key": "screenY",
                "label": "ScreenY",
                "type": "number",
                "subschema": []
            },
            {
                "key": "shiftKey",
                "label": "ShiftKey",
                "type": "boolean",
                "subschema": []            
            }
        ]}
    ],
    "clipboardevents":[
        {"name": "onCopy", "properties":[]},
        {"name": "onCut", "properties":[]},
        {"name": "onPaste", "properties":[]},
    ],
    "mediaevents":[
        {"name": "onCanPlay", "properties": []},
        {"name": "onCanPlayThrough", "properties": []},
        {"name": "onDurationChange", "properties": []},
        {"name": "onEmptied", "properties": []},
        {"name": "onEncrypted", "properties": []},
        {"name": "onEnded", "properties": []},
        {"name": "onError", "properties": []},
        {"name": "onLoadedData", "properties": []},
        {"name": "onLoadedMetadata", "properties": []},
        {"name": "onLoadStart", "properties": []},
        {"name": "onPause", "properties": []},
        {"name": "onPlay", "properties": []},
        {"name": "onPlaying", "properties": []},
        {"name": "onProgress", "properties": []},
        {"name": "onRateChange", "properties": []},
        {"name": "onSeeked", "properties": []},
        {"name": "onSeeking", "properties": []},
        {"name": "onStalled", "properties": []},
        {"name": "onSuspend", "properties": []},
        {"name": "onTimeUpdate", "properties": []},
        {"name": "onVolumeChange", "properties": []},
        {"name": "onWaiting", "properties": []},
    ]
}

export const HtmlComponents = [
    {
        "tag": "div",
        "elemtype": "native",
        "childenabled": false,
        "icon": "",
        "childs":[],
        "innerhtml":[
            {
                "key": "value",
                "label": "Value",
                "type": "string",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]

            }
        ],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "p",
        "icon": "",
        "elemtype": "native",
        "childenabled": false,
        "childs":[],
        "innerhtml":[
            {
                "key": "value",
                "label": "Value",
                "type": "string",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            }
        ],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "span",
        "icon": "",
        "elemtype": "native",
        "childenabled": false,
        "childs":[],
        "props":[],
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "a",
        "icon":"",
        "elemtype": "native",
        "childenabled": false,
        "childs":[],
        "innerhtml":[
            {
                "key": "value",
                "label": "Value",
                "type": "string",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]

            }
        ],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "props":[
            {
                "key": "href",
                "label": "Href",
                "type": "string",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true, 
                "subschema": []
            }
        ],
        "propsmapping":[
            {
                "key": "href",
                "mapping": ""
            }
        ],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "img",
        "icon": "",
        "childenabled": false,
        "elemtype": "native",
        "childs":[],
        "childenabled": false,
        "props":[
            {
                "key": "src",
                "label": "Src",
                "type": "file",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]

            }
        ],
        "propsmapping":[
            {
                "key": "src",
                "mapping":""
            }
        ],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "input",
        "icon": "",
        "elemtype": "native",
        "type": "text",
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"],
            ...InteractionEvents["formevents"]
        ]
    },
    {
        "tag": "select",
        "childenabled": false,
        "elemtype": "native",
        "childs": [],
        "props":[
            {
                "key": "name",
                "label": "Name",
                "type": "string",
                "required": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[] 
            },
            {
                "key": "disabled",
                "label": "Disabled",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "default": false,
                "subschema":[]
            },
            {
                "key": "multiple",
                "label": "Multiple",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "default": false,
                "subschema":[]
            }
        ],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"],
            ...InteractionEvents["formevents"]
        ]
    },
    {
        "tag": "option",
        "childenabled": false,
        "elemtype": "native",
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "props":[
            {
                "key": "value",
                "label": "Value",
                "type": "string",
                "required": true,
                "subschema": [],
                "fillallowed": true,
                "guided": false,
                "guidefinished": true
            }
        ],
        "propsmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "childs": [],
        "interactions":[]
    },
    {
        "tag": "form",
        "elemtype": "native",
        "childenabled": false,
        "props":[
            {
                "key": "action",
                "label": "Action",
                "type": "string",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            },
            {
                "key": "method",
                "label": "Method",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[],
                "options":[
                    {"name": "post", "value": "post"},
                    {"name": "get", "value": "get"}
                ]
            },
            {
                "key": "target",
                "label": "Target",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[],
                "options":[
                    {"name": "self", "value": "_self"},
                    {"name": "blank", "value": "_blank"},
                    {"name": "parent", "value": "_parent"},
                    {"name": "top", "value": "_top"}
                ]
            }
        ],
        "propsmapping":[
            {
                "key": "action",
                "mapping": ""
            },
            {
                "key": "method",
                "mapping": ""
            },
            {
                "key": "target",
                "mapping": ""
            }
        ],
        "childs": [],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"],
            ...InteractionEvents["formevents"]
        ]
    },
    {
        "tag": "button",
        "elemtype": "native",
        "childenabled": false,
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": {
                    "action": "const",
                    "val": "button"
                }
            }
        ],
        "props":[
            {
                "key": "type",
                "label": "Type",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[],
                "options":[
                    {"name": "button", "value": "button"},
                    {"name": "submit", "value": "submit"},
                    {"name": "reset", "value": "reset"}
                ]
            },
            {
                "key": "disabled",
                "label": "Disabled",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            }
        ],
        "propsmapping":[
            {
                "key": "type",
                "mapping": ""
            },
            {
                "key": "disabled",
                "mapping":""
            }
        ],
        "childs": [],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"],
            ...InteractionEvents["formevents"]
        ]
    },
    {
        "tag" : "table",
        "elemtype": "native",
        "childenabled": false,
        "childs":[],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "thead",
        "elemtype": "native",
        "childenabled": false,
        "childs": [],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "tbody",
        "elemtype": "native",
        "childenabled": false,
        "childs": [],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "tr",
        "elemtype": "native",
        "childenabled": false,
        "childs": [],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "td",
        "elemtype": "native",
        "childenabled": false,
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "childs":[],
        "interactions": [
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]

    },
    {
        "tag": "svg",
        "elemtype": "native",
        "childenabled": false,
        "childs":[],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "h1",
        "elemtype": "native",
        "childenabled": false,
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "childs":[],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "h2",
        "elemtype": "native",
        "childenabled": false,
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "childs":[],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "h3",
        "elemtype": "native",
        "childenabled": false,
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "childs": [],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "h4",
        "elemtype": "native",
        "childenabled": false,
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "childs": [],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "h5",
        "elemtype": "native",
        "childenabled": false,
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "childs": [],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "h6",
        "elemtype": "native",
        "childenabled": false,
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "childs": [],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "ol",
        "elemtype": "native",
        "childenabled": false,
        "childs": [],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "ul",
        "elemtype": "native",
        "childenabled": false,
        "childs":[],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "li",
        "elemtype": "native",
        "childenabled": false,
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "childs":[],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "pre",
        "elemtype": "native",
        "childenabled": false,
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "childs":[],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "iframe",
        "elemtype": "native",
        "childenabled": false,
        "props":[
            {
                "key": "src",
                "label": "src",
                "type": "string",
                "required": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            },
            {
                "key": "srcdoc",
                "label": "srcdoc",
                "type": "string",
                "required": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            },
            {
                "key": "allow",
                "label": "Allow",
                "type": "array",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[
                    {
                        "type": "string"
                    }
                ]
            },
            {
                "key": "loading",
                "label": "Loading",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[],
                "options":[
                    {"name": "eager", "value": "eager"},
                    {"name": "lazy", "value": "lazy"}
                ]
            },
            {
                "key": "refererpolicy",
                "label": "refererpolicy",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[],
                "options":[
                    {"name": "no-referrer", "value": "no-referrer"},
                    {"name": "no-referrer-when-downgrade", "value": "no-referrer-when-downgrade"},
                    {"name": "origin", "value": "origin"},
                    {"name": "origin-when-cross-origin", "value": "origin-when-cross-origin"},
                    {"name": "same-origin", "value": "same-origin"},
                    {"name": "strict-origin", "value": "strict-origin"},
                    {"name": "strict-origin-when-cross-origin", "value": "strict-origin-when-cross-origin"},
                    {"name": "unsafe-url", "value": "unsafe-url"}
                ]
            },
            {
                "key": "sandbox",
                "label": "sandbox",
                "type": "array",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[{
                    "type": "string"
                }]
            }
        ],
        "childs":[],
        "interactions":[
            ...InteractionEvents["mouseevents"],
            ...InteractionEvents["dragevents"],
            ...InteractionEvents["clipboardevents"]
        ]
    },
    {
        "tag": "router",
        "elemtype": "native",
        "childenabled": false,
        "routes": [{
            "type": "block",
            "path": "/",
            "params":[],
            "conditions":[[]],
            "fallback": "/",
            "template": ""
        }]
    },
    {
        "tag": "path",
        "elemtype": "native",
        "childs":[],
        "interactions":[]
    }
]

export const HtmlGenericInteractions = [
                                        ...InteractionEvents["mouseevents"],
                                        ...InteractionEvents["dragevents"],
                                        ...InteractionEvents["clipboardevents"]
                                       ]

export const HeaderComponents = [
    {
        "tag": "script",
        "props":[

        ]
    },
    {
        "tag": "title",
        "props":[
            
        ]
    }
]

export const StyleSettings = [

    {
        "name": "accent-color",
        "type": "color"
    },
    {
        "name": "align-content",
        "type": "value",
        "values": [
            "start",
            "end",
            "flex-start",
            "flex-end",
            "center",
            "normal",
            "space-between",
            "space-around",
            "space-evenly",
            "stretch",
            "safe",
            "unsafe"
        ],
        "units":[]
    },
    {
        "name": "align-items",
        "type": "value",
        "values":[
            "normal",
            "flex-start",
            "flex-end",
            "center",
            "start",
            "end",
            "self-start",
            "self-end",
            "stretch",
            "safe",
            "unsafe"
        ],
        "units":[]
    },
    {
        "name": "align-safe",
        "type": "value",
        "values":[
            "auto",
            "normal",
            "self-start",
            "self-end",
            "flex-start",
            "flex-end",
            "center",
            "stretch",
            "safe",
            "unsafe"
        ],
        "units":[]
    },
    {
        "name": "all",
        "type": "value",
        "values":[
            "initial",
            "inherit",
            "unset",
            "revert",
            "revert-layer"
        ],
        "units":[]
    },
    {
        "name": "animation-composition",
        "type": "value",
        "values":[
            "replace",
            "add",
            "accumulate"
        ],
        "units":[]
    },
    {
        "name": "animation-delay",
        "type": "number",
        "values":[

        ],
        "units":[
            "s",
            "ms"
        ]
    },
    {
        "name": "animation-direction",
        "type": "value",
        "values":[
            "normal",
            "reverse",
            "alternate",
            "alternate-reverse"
        ],
        "units":[]
    },
    {
        "name": "animation-duration",
        "type": "number",
        "values":[],
        "units":[
            "s",
            "ms"
        ]
    },
    {
        "name": "animation-fill-mode",
        "type": "value",
        "values": [
            "none",
            "forwards",
            "backwards",
            "both"
        ],
        "units":[]
    },
    {
        "name": "animation-iteration-count",
        "type": "steps",
        "default": "custom",
        "steps":[
            {
                "name": "options",
                "type": "value",
                "values":["infinite"]
            },
            {
                "name": "custom",
                "type": "number",
                "values": [],
                "units": ["s", "ms"]
            }
        ]
    },
    {
        "name": "animation-name",
        "type": "text",
        "values": [],
        "units": []
    },
    {
        "name": "animation-play-state",
        "type": "value",
        "values": [
            "running",
            "paused"
        ],
        "units":[]
    },
    {
        "name": "animation-timing-function",
        "type": "steps",
        "default": "predefined",
        "steps":[
            {
                "name": "predefined",
                "type": "value",
                "values":[
                    "ease",
                    "linear",
                    "ease-in",
                    "ease-out",
                    "ease-in-out",
                    "cubic-bezier",
                    "step-start",
                    "step-end"
                ],
                "units": []
            },
            {
                "name": "custom",
                "type": "text",
                "values": [],
                "units": []
            }
        ]
        
    },
    {
        "name": "aspect-ratio",
        "type": "steps",
        "default": "predefined",
        "steps":[
            {
                "name":"predefined",
                "type":"value",
                "values":[
                    "inherit",
                    "initial",
                    "revert",
                    "revert-layer",
                    "unset",
                    "auto"
                ],
                "units":[]
            },
            {
                "name": "custom",
                "type": "number",
                "values":[],
                "units":[]
            }
        ]
    },
    {
        "name": "backdrop-filter",
        "type":"function",
        "functions": [
            {
                "name": "blur",
                "argtype": "number",
                "units":[
                    "px",
                    "em"
                ]
            },
            {
                "name": "brightness",
                "argtype": "number",
                "units":[
                    "%"
                ]
            },
            {
                "name": "contrast",
                "argtype": "number",
                "units":[
                    "%"
                ]
            },
            {
                "name": "drop-shadow",
                "argtype": "array",
                "args":[
                    {
                        "name": "x",
                        "type": "number",
                        "units":[
                            "px",
                            "em"
                        ]
                    },
                    {
                        "name": "y",
                        "type": "number",
                        "units":[
                            "px",
                            "em"
                        ]
                    },
                    {
                        "name": "z",
                        "type": "number",
                        "units":[
                            "px",
                            "em"
                        ]
                    },
                    {
                        "name": "color",
                        "type": "color"
                    }
                ]
            },
            {
                "name": "grayscale",
                "type": "number",
                "units": [
                    "%"
                ]
            },
            {
                "name": "hue-rotate",
                "type": "number",
                "units": [
                    "deg"
                ]
            },
            {
                "name": "invert",
                "type": "number",
                "units": [
                    "%"
                ]
            },
            {
                "name": "opacity",
                "type": "number",
                "units":[
                    "%"
                ]
            },
            {
                "name": "sepia",
                "type": "number",
                "units": [
                    "%"
                ]
            },
            {
                "name": "saturate",
                "type": "number",
                "units":[
                    "%"
                ]
            }
        ]
    },
    {
        "name": "backface-visibility",
        "type": "value",
        "values": [
            "visible",
            "hidden",
            "inherit",
            "initial",
            "revert",
            "revert-layer",
            "unset"
        ],
        "units":[]
    },
    {
        "name": "background-attachment",
        "type": "value",
        "values":[
            "scroll",
            "fixed",
            "local",
            "inherit",
            "initial",
            "revert",
            "revert-layer",
            "unset"
        ],
        "units":[]
    },
    {
        "name": "background-blend-mode",
        "type": "value",
        "values":[
            "normal",
            "multiply",
            "screen",
            "overlay",
            "darken",
            "lighten",
            "color-dodge",
            "color-burn",
            "hard-light",
            "soft-light",
            "difference",
            "exclusion",
            "hue",
            "saturation",
            "color",
            "luminosity"
        ],
        "units": []
    },
    {
        "name": "background-clip",
        "type": "value",
        "values":[
            "border-box",
            "padding-box",
            "content-box",
            "text"
        ]
    },
    {
        "name": "background-color",
        "type": "color"
    },
    {
        "name": "background-image",
        "type": "image"
    },
    {
        "name": "background-origin",
        "type": "value",
        "values":[
            "border-box",
            "padding-box",
            "content-box"
        ],
        "units":[]
    },
    {
        "name": "background-position",
        "type": "value",
        "values": [
            "top",
            "bottom",
            "left",
            "right",
            "center"
        ],
        "units":[]
    },
    {
        "name": "background-position-x",
        "type": "number",
        "units": [
            "%",
            "em"
        ]
    },
    {
        "name": "background-position-y",
        "type": "number",
        "units":[
            "%",
            "em"
        ]
    },
    {
        "name": "background-repeat",
        "type": "value",
        "values":[
            "repeat-x",
            "repeat-y",
            "repeat",
            "space",
            "round",
            "no-repeat"
        ],
        "units":[]
    },
    {
        "name": "background-size",
        "type": "steps",
        "default": "predefined",
        "steps":[
            {
                "name": "predefined",
                "type": "value",
                "values":[
                    "contain",
                    "cover",
                    "auto"
                ]
            },
            {
                "name": "custom",
                "type": "number",
                "units":[
                    "%",
                    "em"
                ]
            }
        ]
    },
    {
        "name": "block-size",
        "type": "steps",
        "default": "value",
        "steps":[
            {
                "name": "value",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            }
        ]
    },
    {
        "name": "border-width",
        "type": "number",
        "units":[
            "%",
            "px",
            "cm",
            "mm",
            "Q",
            "in",
            "pc",
            "pt",
            "cap",
            "ch",
            "em",
            "ex",
            "ic",
            "lh",
            "rcap",
            "rch",
            "rem",
            "rex",
            "ric",
            "rlh",
            "vh",
            "vw",
            "vmax",
            "vmin",
            "vb",
            "vi",
            "cqw",
            "cqh",
            "cqi",
            "cqb",
            "cqmin",
            "cqmax"
        ]
    },
    {
        "name": "border-style",
        "type": "value",
        "values":[
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ]
    },
    {
        "name": "border-color",
        "type": "color"
    },
    {
        "name": "border-top-width",
        "type": "number",
        "units":[
            "%",
            "px",
            "cm",
            "mm",
            "Q",
            "in",
            "pc",
            "pt",
            "cap",
            "ch",
            "em",
            "ex",
            "ic",
            "lh",
            "rcap",
            "rch",
            "rem",
            "rex",
            "ric",
            "rlh",
            "vh",
            "vw",
            "vmax",
            "vmin",
            "vb",
            "vi",
            "cqw",
            "cqh",
            "cqi",
            "cqb",
            "cqmin",
            "cqmax"
        ]
    },
    {
        "name": "border-top-style",
        "type": "value",
        "values":[
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ]
    },
    {
        "name": "border-top-color",
        "type": "color"
    },
    {
        "name": "border-bottom-width",
        "type": "number",
        "units":[
            "%",
            "px",
            "cm",
            "mm",
            "Q",
            "in",
            "pc",
            "pt",
            "cap",
            "ch",
            "em",
            "ex",
            "ic",
            "lh",
            "rcap",
            "rch",
            "rem",
            "rex",
            "ric",
            "rlh",
            "vh",
            "vw",
            "vmax",
            "vmin",
            "vb",
            "vi",
            "cqw",
            "cqh",
            "cqi",
            "cqb",
            "cqmin",
            "cqmax"
        ]
    },
    {
        "name": "border-bottom-style",
        "type": "value",
        "values":[
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ]
    },
    {
        "name": "border-bottom-color",
        "type": "color"
    },
    {
        "name": "border-right-width",
        "type": "number",
        "units":[
            "%",
            "px",
            "cm",
            "mm",
            "Q",
            "in",
            "pc",
            "pt",
            "cap",
            "ch",
            "em",
            "ex",
            "ic",
            "lh",
            "rcap",
            "rch",
            "rem",
            "rex",
            "ric",
            "rlh",
            "vh",
            "vw",
            "vmax",
            "vmin",
            "vb",
            "vi",
            "cqw",
            "cqh",
            "cqi",
            "cqb",
            "cqmin",
            "cqmax"
        ]
    },
    {
        "name": "border-right-style",
        "type": "value",
        "values":[
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ]
    },
    {
        "name": "border-right-color",
        "type": "color"
    },
    {
        "name": "bottom",
        "type": "number",
        "units":[
            "%",
            "px",
            "cm",
            "mm",
            "Q",
            "in",
            "pc",
            "pt",
            "cap",
            "ch",
            "em",
            "ex",
            "ic",
            "lh",
            "rcap",
            "rch",
            "rem",
            "rex",
            "ric",
            "rlh",
            "vh",
            "vw",
            "vmax",
            "vmin",
            "vb",
            "vi",
            "cqw",
            "cqh",
            "cqi",
            "cqb",
            "cqmin",
            "cqmax"
        ]
    },
    {
        "name": "box-shadow",
        "type": "array",
        "components":[
            {
                "name": "x",
                "type": "number",
                "units":[
                    "px"
                ]
            },
            {
                "name": "y",
                "type": "number",
                "units":[
                    "px"
                ]
            },
            {
                "name": "z",
                "type": "number",
                "units": "px"
            },
            {
                "name": "color",
                "type": "color"
            }
        ]
    },
    {
        "name": "box-sizing",
        "type": "value",
        "values":[
            "border-box",
            "content-box"
        ]
    },
    {
        "name": "break-after",
        "type": "value",
        "values":[
            "auto",
            "avoid",
            "always",
            "all",
            "avoid-page",
            "page",
            "left",
            "right",
            "recto",
            "verso",
            "avoid-column",
            "column",
            "avoid-region",
            "region"
        ]
    },
    {
        "name": "break-before",
        "type": "value",
        "values":[
            "auto",
            "avoid",
            "always",
            "all",
            "avoid-page",
            "page",
            "left",
            "right",
            "recto",
            "verso",
            "avoid-column",
            "column",
            "avoid-region",
            "region"
        ]
    },
    {
        "name": "break-inside",
        "type": "value",
        "values":[
            "auto",
            "avoid",
            "always",
            "all",
            "avoid-page",
            "page",
            "left",
            "right",
            "recto",
            "verso",
            "avoid-column",
            "column",
            "avoid-region",
            "region"
        ]
    },
    {

        "name": "caption-side",
        "type": "value",
        "values":[
            "top",
            "bottom",
            "block-start",
            "block-end",
            "inline-start",
            "inline-end"
        ]
    },
    {
        "name": "caret-color",
        "type": "color"
    },
    {
        "name": "clear",
        "type": "value",
        "values":[
            "none",
            "left",
            "right",
            "both",
            "inline-start",
            "inline-end"
        ]
    },
    {
        "name": "color",
        "type": "color"
    },
    {
        "name": "color-scheme",
        "type": "value",
        "values":[
            "normal",
            "light",
            "dark",
            "only"            
        ]
    },
    {
        "name": "column-count",
        "type": "number",
        "units":[]
    },
    {
        "name": "column-fill",
        "type": "value",
        "values":[
            "auto",
            "balance",
            "balance-all"
        ]
    },
    {
        "name": "column-gap",
        "type": "number",
        "units":[
            "%",
            "px",
            "cm",
            "mm",
            "Q",
            "in",
            "pc",
            "pt",
            "cap",
            "ch",
            "em",
            "ex",
            "ic",
            "lh",
            "rcap",
            "rch",
            "rem",
            "rex",
            "ric",
            "rlh",
            "vh",
            "vw",
            "vmax",
            "vmin",
            "vb",
            "vi",
            "cqw",
            "cqh",
            "cqi",
            "cqb",
            "cqmin",
            "cqmax"
        ]
    },
    {
        "name": "column-rule-color",
        "type": "color"        
    },
    {
        "name": "column-rule-style",
        "type": "value",
        "values":[
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ]
    },
    {
        "name": "column-rule-width",
        "type": "steps",
        "default": "custom",
        "steps":[
            {
                "name": "value",
                "type": "value",
                "values":[
                    "thin",
                    "medium",
                    "thick"                    
                ]
            },
            {
                "name": "custom",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            }
        ]
    },
    {
        "name": "column-span",
        "type": "value",
        "values": [
            "none",
            "all"
        ]
    },
    {
        "name": "column-width",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]
    },
    {
        "name": "columns",
        "type": "number",
        "unit":[]
    },
    {
        "name": "contain-intrinsic-block-size",
        "type": "steps",
        "default": "value",
        "steps":[
            {
                "name": "value",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            },
            {
                "name": "custom",
                "type": "value",
                "values":[
                    "none",
                    "auto"
                ]
            }
        ]
    },
    {
        "name": "contain-intrinsic-height",
        "type": "steps",
        "default": "value",
        "steps":[
            {
                "name": "value",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            },
            {
                "name": "custom",
                "type": "value",
                "values":[
                    "none",
                    "auto"
                ]
            }
        ]
    },
    {
        "name": "contain-intrinsic-inline-size",
        "type": "steps",
        "default": "value",
        "steps":[
            {
                "name": "value",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            },
            {
                "name": "custom",
                "type": "value",
                "values":[
                    "none",
                    "auto"
                ]
            }
        ]
    },
    {
        "name": "contain-intrinsic-size",
        "type": "steps",
        "default": "value",
        "steps":[
            {
                "name": "value",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            },
            {
                "name": "custom",
                "type": "value",
                "values":[
                    "none",
                    "auto"
                ]
            }
        ]
    },
    {
        "name": "contain-intrinsic-width",
        "type": "steps",
        "default": "value",
        "steps":[
            {
                "name": "value",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            },
            {
                "name": "custom",
                "type": "value",
                "values":[
                    "none",
                    "auto"
                ]
            }
        ]
    },
    {
        "name": "cursor",
        "type": "value",
        "values": [
            "auto",
            "default",
            "none",
            "context-menu",
            "help",
            "pointer",
            "progress",
            "wait",
            "cell",
            "crosshair",
            "text",
            "vertical-text",
            "alias",
            "copy",
            "move",
            "no-drop",
            "not-allowed",
            "grab",
            "grabbing",
            "all-scroll",
            "col-resize",
            "row-resize",
            "n-resize",
            "e-resize",
            "s-resize",
            "w-resize",
            "ne-resize",
            "nw-resize",
            "se-resize",
            "sw-resize",
            "ew-resize",
            "ns-resize",
            "nesw-resize",
            "nwse-resize",
            "zoom-in",
            "zoom-out"
        ]
    },
    {
        "name": "direction",
        "type": "value",
        "values":[
            "ltr",
            "rtl"
        ]
    },
    {
        "name": "display",
        "type": "value",
        "values": [
            "block",
            "inline",
            "inline-block",
            "flex",
            "inline-flex",
            "grid",
            "inline-grid",
            "flow-root",
            "none",
            "contents",
            "table",
            "table-row",
            "list-item"            
        ]
    },
    {
        "name": "empty-cells",
        "type": "value",
        "values":[
            "show",
            "hide"
        ]
    },
    {
        "name": "flex-basis",
        "type": "steps",
        "default": "custom",
        "steps":[
            {
                "name": "predefined",
                "type": "value",
                "values":[
                    "max-content",
                    "min-content",
                    "fit-content",
                    "auto"
                ]
            },
            {
                "name": "custom",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            }
        ]
    },
    {
        "name": "flex-direction",
        "type": "value",
        "values":[
            "row",
            "row-reverse",
            "column",
            "column-reverse"
        ]
    },
    {
        "name": "flex-flow",
        "type": "value",
        "values":[
            "row",
            "row-reverse",
            "column",
            "column-reverse",
            "nowrap",
            "wrap",
            "wrap-reverse"
        ]
    },
    {
        "name": "flex-grow",
        "type": "number",
        "units":[]
    },
    {
        "name": "flex-shrink",
        "type": "number",
        "units":[]
    },
    {
        "name": "flex-wrap",
        "type": "value",
        "values":[
            "nowrap",
            "wrap",
            "wrap-reverse"
        ]
    },
    {
        "name": "float",
        "type": "value",
        "values":[
            "left",
            "right",
            "none",
            "inline-start",
            "inline-end"
        ]
    },
    {
        "name": "font-family",
        "type": "value",
        "values":[
            "serif",
            "sans-serif",
            "monospace",
            "cursive",
            "fantasy",
            "system-ui",
            "ui-serif",
            "ui-sans-serif",
            "ui-monospace",
            "ui-rounded",
            "emoji",
            "math",
            "fangsong"
        ]
    },
    {
        "name": "font-size",
        "type": "steps",
        "default": "value",
        "steps":[
            {
                "name": "value",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            },
            {
                "name": "predefined",
                "type": "value",
                "values":[
                    "xx-small", 
                    "x-small", 
                    "small", 
                    "medium", 
                    "large", 
                    "x-large", 
                    "xx-large", 
                    "xxx-large",
                    "math"
                ]
            }
        ]
    },
    {
        "name": "font-stretch",
        "type": "steps",
        "default": "percentage",
        "steps":[
            {
                "name": "values",
                "type": "value",
                "values":[
                    "normal",
                    "ultra-condensed",
                    "extra-condensed",
                    "condensed",
                    "semi-condensed",
                    "semi-expanded",
                    "expanded",
                    "extra-expanded",
                    "ultra-expanded"
                ]
            },
            {
                "name": "percentage",
                "type": "number",
                "units":[
                    "%"
                ]
            }
        ]
    },
    {
        "name": "font-style",
        "type": "value",
        "values":[
            "normal",
            "italic",
            "oblique"
        ]
    },
    {
        "name": "font-variant",
        "type": "url"
    },
    {
        "name": "font-weight",
        "type": "steps",
        "default": "custom",
        "steps":[
            {
                "name": "custom",
                "type": "number",
                "units":[]
            },
            {
                "name": "values",
                "type": "value",
                "values":[
                    "normal",
                    "bold",
                    "lighter",
                    "bolder"
                ]
            }
        ]
    },
    {
        "name": "line-height",
        "type": "steps",
        "default": "value",
        "steps":[
            {
                "name": "value",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            },
            {
                "name": "normal",
                "type": "value",
                "values":[
                    "normal"
                ]
            }
        ]
    },
    {
        "name": "forced-color-adjust",
        "type": "value",
        "values":[
            "auto",
            "none"
        ]
    },
    {
        "name": "height",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]

    },
    {
        "name": "inline-size",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]

    },
    {
        "name": "inset-block-end",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]

    },
    {
        "name": "inset-block-start",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]

    },
    {
        "name": "inset-inline-end",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]

    },
    {
        "name": "inset-inline-start",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]

    },
    {
        "name": "isolation",
        "type": "value",
        "values":[
            "auto",
            "isolate"
        ]
    },
    {
        "name": "justify-content",
        "type": "value",
        "values":[
            "start",
            "end",
            "flex-start",
            "flex-end",
            "center",
            "left",
            "right",
            "normal",
            "space-between",
            "space-around",
            "space-evenly",
            "stretch",
            "safe",
            "unsafe"
        ]

    },
    {
        "name": "justify-items",
        "type": "value",
        "values":[
            "normal",
            "stretch",
            "center",
            "start",
            "end",
            "flex-start",
            "flex-end",
            "self-start",
            "self-end",
            "left",
            "right",
            "baseline",
            "first baseline",
            "last baseline",
            "stretch",
            "safe",
            "unsafe",
            "legacy"
        ]
    },
    {
        "name": "justify-self",
        "type": "value",
        "values":[
            "normal",
            "stretch",
            "center",
            "start",
            "end",
            "flex-start",
            "flex-end",
            "self-start",
            "self-end",
            "left",
            "right",
            "baseline",
            "first baseline",
            "last baseline",
            "stretch",
            "safe",
            "unsafe",
            "legacy"
        ]
    },
    {
        "name": "left",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]

    },
    {
        "name": "letter-spacing",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]
    },
    {
        "name": "line-break",
        "type": "value",
        "values":[
            "auto",
            "loose",
            "normal",
            "strict",
            "anywhere"
        ]
    },
    {
        "name": "line-height",
        "type": "number",
        "units": [
            "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]
    },
    {
        "name": "list-style-image",
        "type": "file"
    },
    {
        "name": "list-style-position",
        "type": "value",
        "values":[
            "inside",
            "outside"
        ]
    },
    {
        "name": "list-style-type",
        "type": "text"
    },
    {
        "name": "margin",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]
    },
    {
        "name": "margin-bottom",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]
    },
    {
        "name": "margin-top",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]
    },
    {
        "name": "margin-left",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]
    },
    {
        "name": "margin-right",
        "type": "number",
        "units":[
                "%",
                "px",
                "cm",
                "mm",
                "Q",
                "in",
                "pc",
                "pt",
                "cap",
                "ch",
                "em",
                "ex",
                "ic",
                "lh",
                "rcap",
                "rch",
                "rem",
                "rex",
                "ric",
                "rlh",
                "vh",
                "vw",
                "vmax",
                "vmin",
                "vb",
                "vi",
                "cqw",
                "cqh",
                "cqi",
                "cqb",
                "cqmin",
                "cqmax"
        ]
    },
    {
        "name": "max-block-size",
        "type": "steps",
        "default": "number",
        "steps":[
            {
                "name": "predefined",
                "type": "value",
                "values":[
                    "none",
                    "max-content",
                    "min-content",
                    "fit-content"
                ]
            },
            {
                "name": "custom",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"   
                ]
            }
        ]
    },
    {
        "name": "max-height",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
            ]
    },
    {
        "name": "max-inline-size",
        "type": "steps",
        "default": "custom",
        "steps":[
            {
                "name": "predefined",
                "type": "value",
                "values":[
                    "none",
                    "max-content",
                    "min-content",
                    "fit-content"
                ]
            },
            {
                "name": "custom",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"   
                ]
            }
        ]
    },
    {
        "name": "max-width",
        "type": "number",
        "units": [
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
            ]
    },
    {
        "name": "min-block-size",
        "type": "steps",
        "default": "custom",
        "steps":[
            {
                "name": "predefined",
                "type": "value",
                "values":[
                    "none",
                    "max-content",
                    "min-content",
                    "fit-content"
                ]
            },
            {
                "name": "custom",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"   
                ]
            }
        ]
    },
    {
        "name": "min-height",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
            ]
    },
    {
        "name": "min-inline-size",
        "type": "steps",
        "default": "custom",
        "steps":[
            {
                "name": "predefined",
                "type": "value",
                "values":[
                    "none",
                    "max-content",
                    "min-content",
                    "fit-content"
                ]
            },
            {
                "name": "custom",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"   
                ]
            }
        ]
    },
    {
        "name": "min-width",
        "type": "number",
        "units": [
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
            ]
    },
    {
        "name": "mix-blend-mode",
        "type": "value",
        "values":[
            "normal",
            "multiply",
            "screen",
            "overlay",
            "darken",
            "lighten",
            "color-dodge",
            "color-burn",
            "hard-light",
            "soft-light",
            "difference",
            "exclusion",
            "hue",
            "saturation",
            "color",
            "luminosity",
            "plus-darker",
            "plus-lighter"
        ]
    },
    {
        "name": "object-fit",
        "type": "value",
        "values":[
            "contain",
            "cover",
            "fill",
            "none",
            "scale-down"
        ]
    },
    {
        "name": "object-position",
        "type": "value",
        "values": [

        ]
    },
    {
        "name": "opacity",
        "type": "number",
        "units":[
            "null",
            "%"
        ]
    },
    {
        "name": "order",
        "type": "number",
        "units":[

        ]
    },
    {
        "name": "orphans",
        "type": "number",
        "units":[]
    },
    {
        "name": "outline-color",
        "type": "color"
    },
    {
        "name": "outline-offset",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
            ]
    },
    {
        "name": "outline-style",
        "type": "value",
        "values":[
            "auto",
            "none",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ]
    },
    {
        "name": "outline-width",
        "type": "steps",
        "default": "custom",
        "steps":[
            {
                "name": "value",
                "type": "value",
                "values":[
                    "thin",
                    "medium",
                    "thick"
                ]
            },
            {
                "name": "custom",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            }
        ]
    },
    {
        "name": "overflow",
        "type": "value",
        "values":[
            "visible",
            "hidden",
            "clip",
            "scroll",
            "auto"
        ]
    },
    {
        "name": "overflow-anchor",
        "type": "value",
        "values":[
            "auto",
            "none"
        ]
    },
    {
        "name": "overflow-block",
        "type": "value",
        "values":[
            "visible",
            "hidden",
            "clip",
            "scroll",
            "auto"
        ]
    },
    {
        "name": "overflow-x",
        "type": "value",
        "values":[
            "visible",
            "hidden",
            "clip",
            "scroll",
            "auto"
        ]
    },
    {
        "name": "overflow-y",
        "type": "value",
        "values":[
            "visible",
            "hidden",
            "clip",
            "scroll",
            "auto"
        ]
    },
    {
        "name": "overscroll-behavior",
        "type": "value",
        "values":[
            "auto",
            "contain",
            "none"
        ]
    },
    {
        "name": "overscroll-behavior-block",
        "type": "value",
        "values":[
            "auto",
            "contain",
            "none"
        ]
    },
    {
        "name": "overscroll-behavior-inline",
        "type": "value",
        "values":[
            "auto",
            "contain",
            "none"
        ]
    },
    {
        "name": "overscroll-x",
        "type": "value",
        "values":[
            "auto",
            "contain",
            "none"
        ]
    },
    {
        "name": "overscroll-y",
        "type": "value",
        "values":[
            "auto",
            "contain",
            "none"
        ]
    },
    {
        "name": "padding-block-end",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "padding-block-start",
        "type": "number",
        "units":[
            "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"   
        ]
    },
    {
        "name": "padding-bottom",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "padding-inline-end",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "padding-inline-start",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "padding-left",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "padding-right",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "padding-top",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "padding",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "page-break-after",
        "type": "value",
        "values":[
            "auto",
            "always",
            "avoid",
            "left",
            "right",
            "recto",
            "verso"
        ]
    },
    {
        "name": "page-break-before",
        "type": "value",
        "values":[
            "auto",
            "always",
            "avoid",
            "left",
            "right",
            "recto",
            "verso"
        ]
    },
    {
        "name": "page-break-inside",
        "type": "value",
        "values":[
            "auto",
            "always",
            "avoid",
            "left",
            "right",
            "recto",
            "verso"
        ]
    },
    {
        "name": "perspective",
        "type": "number",
        "units":[
            "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "perspective-origin",
        "type": "text"
    },
    {
        "name": "place-content",
        "type": "text"
    },
    {
        "name": "place-items",
        "type": "text"
    },
    {
        "name": "place-self",
        "type": "text"
    },
    {
        "name": "pointer-events",
        "type": "value",
        "values":[
            "auto",
            "none",
            "visiblePainted",
            "visibleFill",
            "visibleStroke",
            "visible",
            "painted",
            "fill",
            "stroke",
            "all"
        ]
    },
    {
        "name": "position",
        "type": "value",
        "values":[
            "sticky",
            "relative",
            "absolute"
        ]
    },
    {
        "name": "quotes",
        "type": "text"
    },
    {
        "name": "resize",
        "type": "value",
        "values":[
            "none",
            "both",
            "horizontal",
            "vertical",
            "block",
            "inline"
        ]
    },
    {
        "name": "right",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "rotate",
        "type": "text"
    },
    {
        "name": "row-gap",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"    
        ]
    },
    {
        "name": "ruby-position",
        "type": "value",
        "values":[
            "over",
            "under",
            "inter-character",
            "alternate"
        ]
    },
    {
        "name": "scale",
        "type": "text"
    },
    {
        "name": "scroll-behavior",
        "type": "value",
        "values":[
            "auto",
            "smooth"
        ]
    },
    {
        "name": "scroll-margin",
        "type": "number",
        "units":[
            "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"  
        ]
    },
    {
        "name": "scroll-margin-block",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-margin-block-end",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-margin-block-start",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-margin-bottom",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-margin-inline",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-margin-inline-start",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-margin-inline-end",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },  
    {
        "name": "scroll-margin-left",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-margin-right",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-margin-top",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding-block",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding-block-end",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding-block-start",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding-bottom",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding-inline",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding-inline-end",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding-inline-start",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding-left",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding-right",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-padding-top",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "scroll-snap-align",
        "type": "value",
        "values":[
            "none",
            "start",
            "end",
            "center"
        ]
    },
    {
        "name": "scroll-snap-stop",
        "type": "value",
        "values":[
            "normal",
            "always"
        ]
    },
    {
        "name": "scroll-snap-type",
        "type": "value",
        "values":[
            "none",
            "x",
            "y",
            "block",
            "inline",
            "both",
            "mandatory",
            "proximity"
        
        ]
    },
    {
        "name": "scrollbar-color",
        "type": "text"
    },
    {
        "name": "scrollbar-gutter",
        "type": "value",
        "values":[
            "auto",
            "stable",
            "both-edges"            
        ]
    },
    {
        "name": "scrollbar-width",
        "type": "value",
        "values":[
            "auto",
            "thin",
            "none"
        ]
    },
    {
        "name": "shape-image-threshold",
        "type": "number",
        "units":[]
    },
    {
        "name": "shape-margin",
        "type": "number",
        "units":[
            "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "shape-outside",
        "type": "text"
    },
    {
        "name": "tab-size",
        "type": "number",
        "units":[
            "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "table-layout",
        "type": "value",
        "values":[
            "auto",
            "fixed"
        ]
    },
    {
        "name": "text-align",
        "type": "value",
        "values":[
            "start",
            "end",
            "left",
            "right",
            "center",
            "justify",
            "justify-all",
            "match-parent",
            "-moz-center",
            "-webkit-center"
        ]
    },
    {
        "name": "text-align-last",
        "type": "value",
        "values":[
            "auto",
            "start",
            "end",
            "left",
            "right",
            "center",
            "justify"
        ]
    },
    {
        "name": "text-combine-upright",
        "type": "text"
    },
    {
        "name": "text-decoration",
        "type": "text"
    },
    {
        "name": "text-decoration-color",
        "type": "color"
    },
    {
        "name": "text-decoration-line",
        "type": "value",
        "values":[
            "none",
            "underline",
            "overline",
            "line-through",
            "blink"            
        ]
    },
    {
        "name": "text-decoration-skip-ink",
        "type": "value",
        "values":[
            "none",
            "auto",
            "all"
        ]
    },
    {
        "name": "text-decoration-style",
        "type": "value",
        "values":[
            "solid",
            "double",
            "dotted",
            "dashed",
            "wavy"
        ]
    },
    {
        "name": "text-decoration-thickness",
        "type": "number",
        "values":[
            "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "text-emphasis",
        "type": "text"
    },
    {
        "name": "text-emphasis-color",
        "type": "color"
    },
    {
        "name": "text-emphasis-position",
        "type": "value",
        "values":[
            "over right",
            "over left",
            "under right",
            "under left",
            "left over",
            "right under",
            "left under"
        ]
    },
    {
        "name": "text-emphasis-style",
        "type": "steps",
        "default": "values",
        "steps":[
            {
                "name": "values",
                "type": "value",
                "values":[
                    "filled",
                    "open",
                    "dot",
                    "circle",
                    "double-circle",
                    "triangle",
                    "filled sesame",
                    "open sesame"
                ]
            },
            {
                "name": "custom",
                "type": "text"
            }
        ]
    },
    {
        "name": "text-justify",
        "type": "value",
        "values":[
            "none",
            "auto",
            "inter-word",
            "inter-character",
            "distribute"
        ]
    },
    {
        "name": "text-orientation",
        "type": "value",
        "values":[
            "mixed",
            "upright",
            "sideways-right",
            "sideways",
            "use-glyph-orientation"
        ]
    },
    {
        "name": "text-overflow",
        "type": "text",
    },
    {
        "name": "text-shadow",
        "type": "text"
    },
    {
        "name": "text-transform",
        "type": "value",
        "values":[
            "none",
            "capitalize",
            "uppercase",
            "lowercase",
            "full-width",
            "full-size-kana"
        ]        
    },
    {
        "name": "text-underline-offset",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "text-underline-position",
        "type": "text"
    },
    {
        "name": "text-wrap",
        "type": "value",
        "values":[
            "wrap",
            "nowrap",
            "balance",
            "pretty",
            "stable"
        ]
    },
    {
        "name": "top",
        "type": "number",
        "units":[
            "%",
            "px",
            "cm",
            "mm",
            "Q",
            "in",
            "pc",
            "pt",
            "cap",
            "ch",
            "em",
            "ex",
            "ic",
            "lh",
            "rcap",
            "rch",
            "rem",
            "rex",
            "ric",
            "rlh",
            "vh",
            "vw",
            "vmax",
            "vmin",
            "vb",
            "vi",
            "cqw",
            "cqh",
            "cqi",
            "cqb",
            "cqmin",
            "cqmax"
        ]
    },
    {
        "name": "touch-action",
        "type": "text"
    },
    {
        "name": "transform",
        "type": "text"
    },
    {
        "name": "transform-box",
        "type": "text"
    },
    {
        "name": "transform-origin",
        "type": "text"
    },
    {
        "name": "transform-style",
        "type": "text"
    },
    {
        "name": "transition",
        "type": "text"
    },
    {
        "name": "transition-delay",
        "type": "text"
    },
    {
        "name": "transition-duration",
        "type": "text"
    },
    {
        "name": "transition-property",
        "type": "text"
    },
    {
        "name": "transition-timing-function",
        "type": "text"
    },
    {
        "name": "translate",
        "type": "text"
    },
    {
        "name": "unicode-bidi",
        "type": "value",
        "values":[
            "normal",
            "embed",
            "isolate",
            "bidi-override",
            "isolate-override",
            "plaintext"
        ]
    },
    {
        "name": "user-select",
        "type": "value",
        "values":[
            "none",
            "auto",
            "text",
            "contain",
            "all"
        ]
    },
    {
        "name": "vertical-align",
        "type": "steps",
        "default": "custom",
        "steps":[
            {
                "name": "values",
                "type": "value",
                "values":[
                    "baseline",
                    "sub",
                    "super",
                    "text-top",
                    "text-bottom",
                    "middle",
                    "top",
                    "bottom"
                ]
            },
            {
                "name": "custom",
                "type": "number",
                "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
                ]
            }
        ]
    },
    {
        "name": "visibility",
        "type": "value",
        "values":[
            "visible",
            "hidden",
            "collapse"
        ]
    },
    {
        "name": "white-space",
        "type": "value",
        "values":[
            "normal",
            "nowrap",
            "pre",
            "pre-wrap",
            "pre-line",
            "break-spaces"
        ]
    },
    {
        "name": "widows",
        "type": "number",
        "units":[]
    },
    {
        "name": "width",
        "type": "number",
        "units":[
                    "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "word-break",
        "type": "value",
        "values":[
            "normal",
            "break-all",
            "keep-all",
            "auto-phrase",
            "break-word"
        ]
    },
    {
        "name": "word-spacing",
        "type": "number",
        "units":[
            "%",
                    "px",
                    "cm",
                    "mm",
                    "Q",
                    "in",
                    "pc",
                    "pt",
                    "cap",
                    "ch",
                    "em",
                    "ex",
                    "ic",
                    "lh",
                    "rcap",
                    "rch",
                    "rem",
                    "rex",
                    "ric",
                    "rlh",
                    "vh",
                    "vw",
                    "vmax",
                    "vmin",
                    "vb",
                    "vi",
                    "cqw",
                    "cqh",
                    "cqi",
                    "cqb",
                    "cqmin",
                    "cqmax"
        ]
    },
    {
        "name": "writing-mode",
        "type": "value",
        "values":[
            "horizontal-tb",
            "vertical-rl",
            "vertical-rl",
            "sideways-rl",
            "sideways-lr"
        ]
    },
    {
        "name": "z-index",
        "type": "number",
        "units":[]
    }

    

    

]

export const InteractionSettings = [
    {"name": "onCompositionEnd", 
    "properties":[
        {
            "key": "data",
            "label": "Data",
            "type": "string",
            "subschema": [],
            "value": ""
        }
    ]},
    {"name": "onCompositionStart", "properties":[
        {
            "key": "data",
            "label": "Data",
            "type": "string",
            "subschema": [],
            "value": ""
        }
    ]},
    {"name": "onCompositionUpdate", "properties":[
        {
            "key": "data",
            "label": "Data",
            "type": "string",
            "subschema": [],
            "value": ""
        }
    ]},
    {"name": "onKeyDown", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema": [],
            "value": ""
        },
        {
            "key": "charCode",
            "label": "CharCode",
            "type": "number",
            "subschema": [],
            "value": ""
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": [],
            "value": ""
        },
        {
            "key": "key",
            "label": "Key",
            "type": "string",
            "subschema": [],
            "value": ""
        },
        {
            "key": "keyCode",
            "label": "KeyCode",
            "type": "number",
            "subschema": [],
            "value": ""
        },
        {
            "key": "locale",
            "label": "Locale",
            "type": "string",
            "subschema": [],
            "value": ""
        },
        {
            "key": "location",
            "label": "Location",
            "type": "number",
            "subschema": [],
            "value": ""
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema": [],
            "value": ""
        },
        {
            "key": "repeat",
            "label": "Repeat",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "which",
            "label": "Which",
            "type": "number",
            "subschema": []
        }
    ]},
    {"name": "onKeyPress", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "charCode",
            "label": "CharCode",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "key",
            "label": "Key",
            "type": "string",
            "subschema": []
        },
        {
            "key": "keyCode",
            "label": "KeyCode",
            "type": "number",
            "subschema": []
        },
        {
            "key": "locale",
            "label": "Locale",
            "type": "string",
            "subschema": []
        },
        {
            "key": "location",
            "label": "Location",
            "type": "number",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "repeat",
            "label": "Repeat",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "which",
            "label": "Which",
            "type": "number",
            "subschema": []
        }

    ]},
    {"name": "onKeyUp", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "charCode",
            "label": "CharCode",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "key",
            "label": "Key",
            "type": "string",
            "subschema": []
        },
        {
            "key": "keyCode",
            "label": "KeyCode",
            "type": "number",
            "subschema": []
        },
        {
            "key": "locale",
            "label": "Locale",
            "type": "string",
            "subschema": []
        },
        {
            "key": "location",
            "label": "Location",
            "type": "number",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "repeat",
            "label": "Repeat",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "which",
            "label": "Which",
            "type": "number",
            "subschema": []
        }


    ]},
    {"name": "onFocus", "properties":[]},
    {"name": "onBlur", "properties":[]},
    {"name": "onClick", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onContextMenu", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onDoubleClick", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onDrag", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onDragEnd", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onDragEnter", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onDragExit", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onDragLeave", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onDragOver", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onDragStart", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onDrop", "properties":[
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onMouseDown", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onMouseEnter", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onMouseLeave", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onMouseMove", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onMouseOut", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onMouseOver", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onMouseUp", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "button",
            "label": "Button",
            "type": "number",
            "subschema": []
        },
        {
            "key": "buttons",
            "label": "Buttons",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientX",
            "label": "ClientX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "clientY",
            "label": "ClientY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "pageX",
            "label": "PageX",
            "type": "number",
            "subschema":[]
        },
        {
            "key": "pageY",
            "label": "PageY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenX",
            "label": "ScreenX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "screenY",
            "label": "ScreenY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []            
        }
    ]},
    {"name": "onSelect", "properties": [
    ]},
    {"name": "onTouchCancel", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "changedTouches",
            "label": "ChangedTouches",
            "type": "array",
            "subschema":[
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "targetTouches",
            "label": "TargetTouches",
            "type": "array",
            "subschema": [
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        },
        {
            "key": "touches",
            "label": "Touches",
            "type": "array",
            "subschema": [
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        }
    ]},
    {"name": "onTouchEnd", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "changedTouches",
            "label": "ChangedTouches",
            "type": "array",
            "subschema":[
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "targetTouches",
            "label": "TargetTouches",
            "type": "array",
            "subschema": [
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        },
        {
            "key": "touches",
            "label": "Touches",
            "type": "array",
            "subschema": [
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        }

    ]},
    {"name": "onTouchMove", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "changedTouches",
            "label": "ChangedTouches",
            "type": "array",
            "subschema":[
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "targetTouches",
            "label": "TargetTouches",
            "type": "array",
            "subschema": [
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        },
        {
            "key": "touches",
            "label": "Touches",
            "type": "array",
            "subschema": [
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        }
    ]},
    {"name": "onTouchStart", "properties": [
        {
            "key": "altKey",
            "label": "AltKey",
            "type": "boolean",
            "subschema":[]
        },
        {
            "key": "changedTouches",
            "label": "ChangedTouches",
            "type": "array",
            "subschema":[
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        },
        {
            "key": "ctrlKey",
            "label": "CtrlKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "metaKey",
            "label": "MetaKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "shiftKey",
            "label": "ShiftKey",
            "type": "boolean",
            "subschema": []
        },
        {
            "key": "targetTouches",
            "label": "TargetTouches",
            "type": "array",
            "subschema": [
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        },
        {
            "key": "touches",
            "label": "Touches",
            "type": "array",
            "subschema": [
                {
                    "type": "object",
                    "subschema":[]
                }
            ]
        }
    ]},
    {"name": "onScroll", "properties": [
        {
            "key": "detail",
            "label": "Detail",
            "type": "number",
            "subschema": []
        },
        {
            "key": "view",
            "label": "View",
            "type": "object",
            "subschema": []
        }
    ]},
    {"name": "onWheel", "properties": [
        {
            "key": "deltaMode",
            "label": "DeltaMode",
            "type": "number",
            "subschema": []
        },
        {
            "key": "deltaX",
            "label": "DeltaX",
            "type": "number",
            "subschema": []
        },
        {
            "key": "deltaY",
            "label": "DeltaY",
            "type": "number",
            "subschema": []
        },
        {
            "key": "deltaZ",
            "label": "DeltaZ",
            "type": "number",
            "subschema": []
        }
    ]},
    {"name": "onAbort", "properties": [

    ]},
    {"name": "onCanPlay", "properties": []},
    {"name": "onCanPlayThrough", "properties": []},
    {"name": "onDurationChange", "properties": []},
    {"name": "onEmptied", "properties": []},
    {"name": "onEncrypted", "properties": []},
    {"name": "onEnded", "properties": []},
    {"name": "onError", "properties": []},
    {"name": "onLoadedData", "properties": []},
    {"name": "onLoadedMetadata", "properties": []},
    {"name": "onLoadStart", "properties": []},
    {"name": "onPause", "properties": []},
    {"name": "onPlay", "properties": []},
    {"name": "onPlaying", "properties": []},
    {"name": "onProgress", "properties": []},
    {"name": "onRateChange", "properties": []},
    {"name": "onSeeked", "properties": []},
    {"name": "onSeeking", "properties": []},
    {"name": "onStalled", "properties": []},
    {"name": "onSuspend", "properties": []},
    {"name": "onTimeUpdate", "properties": []},
    {"name": "onVolumeChange", "properties": []},
    {"name": "onWaiting", "properties": []},
    {"name": "onAnimationStart", "properties": [
        {
            "key": "animationName",
            "label": "AnimationName",
            "type": "string",
            "subschema": []
        },
        {
            "key": "pseudoElement",
            "label": "PsuedoElement",
            "type": "string",
            "subschema": []
        },
        {
            "key": "elapsedTime",
            "label": "ElapsedTime",
            "type": "number",
            "subschema": []
        }
    ]},
    {"name": "onAnimationEnd", "properties": [
        {
            "key": "animationName",
            "label": "AnimationName",
            "type": "string",
            "subschema": []
        },
        {
            "key": "pseudoElement",
            "label": "PsuedoElement",
            "type": "string",
            "subschema": []
        },
        {
            "key": "elapsedTime",
            "label": "ElapsedTime",
            "type": "number",
            "subschema": []
        }
    ]},
    {"name": "onAnimationIteration", "properties": [
        {
            "key": "animationName",
            "label": "AnimationName",
            "type": "string",
            "subschema": []
        },
        {
            "key": "pseudoElement",
            "label": "PsuedoElement",
            "type": "string",
            "subschema": []
        },
        {
            "key": "elapsedTime",
            "label": "ElapsedTime",
            "type": "number",
            "subschema": []
        }
    ]},
    {"name": "onTransitionEnd", "properties": [
        {
            "key": "animationName",
            "label": "AnimationName",
            "type": "string",
            "subschema": []
        },
        {
            "key": "pseudoElement",
            "label": "PsuedoElement",
            "type": "string",
            "subschema": []
        },
        {
            "key": "elapsedTime",
            "label": "ElapsedTime",
            "type": "number",
            "subschema": []
        }
    ]},
    {"name": "onToggle", "properties": []}
]

export const  TypeProps = [
    {
        "type": "button",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "value": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "value": false
            }
        ],
        "outputtype": null
    },
    {
        "type": "checkbox",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "required",
                "type": "boolean",
                "value": false,
                "default": false
            }
        ],
        "outputtype": "boolean"
    },
    {
        "type": "color",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "list",
                "type": "text",
                "value": "",
                "default": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "date",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "min",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "max",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "step",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "datetime"
    },
    {
        "type": "datetime-local",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "min",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "max",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "value": "",
                "default": ""
            }
        ],
        "outputtype": "datetime"
    },
    {
        "type": "email",
        "props":[
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "minlength",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "dirname",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "size",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "multiple",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "defalut": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "file",
        "props":[
            {
                "name": "accept",
                "type": "text",
                "default": "*",
                "value": "*"
            },
            {
                "name": "multiple",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "file"
    },
    {
        "type": "hidden",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "value",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "name": "image",
        "props":[
            {
                "name": "alt",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "height",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "src",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "width",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "file"
    },
    {
        "type": "month",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "min",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "max",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "number",
        "props":[
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "multiple",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "number"
    },
    {
        "name": "password",
        "props":[
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "radio",
        "props":[
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            }
        ],
        "outputtype": "boolean"
    },
    {
        "type": "range",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "step",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "number"
    },
    {
        "type": "reset",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            }
        ],
        "outputtype": null
    },
    {
        "type": "search",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "submit",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            }
        ],
        "outputtype": null
    },
    {
        "type": "tel",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "text",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "time",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "min",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "max",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "step",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "week",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "min",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "max",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "step",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "url",
        "props":[
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    }
]

export const NativeElements = [
    {
        "tag": "ActivityIndicator",
        "type": "native",
        "props":[
            {
                "key": "animating",
                "type": "boolean",
                "required": true,
                "default": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            },
            {
                "key": "color",
                "type": "string",
                "required": false,
                "default": null,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            },
            {
                "key": "size",
                "type": "enum",
                "required": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "options":[
                    {
                        "name": "small",
                        "value": "small"
                    },
                    {
                        "name": "large",
                        "value": "large"
                    }
                ],
                "default": "small",
                "subschema": []
            }
        ],
        "propsmapping": [
            {
                "key": "animating",
                "mapping": ""
            },
            {
                "key": "color",
                "mapping": ""
            },
            {
                "key": "size",
                "mapping": ""
            }
        ],
        "interactions":[],
        "styles":[]
    },
    {
        "tag": "Button",
        "type": "native",
        "props":[
            {
                "key": "title",
                "label": "Title",
                "type": "string",
                "required": true,
                "subschema": []
            },
            {
                "key": "accessibilityLabel",
                "label": "accessibilityLabel",
                "type": "string",
                "required": false,
                "subschema": []
            },
            {
                "key": "accessibilityActions",
                "label": "accessibilityActions",
                "type": "array",
                "required": false,
                "subschema":[{
                    "type": "object",
                    "subschema":[
                        {
                            "key": "name",
                            "label": "Name",
                            "required": true,
                            "type": "string",
                            "subschema":[]
                        },
                        {
                            "key": "label",
                            "label": "label",
                            "required": true,
                            "type": "string",
                            "subschema": []
                        }
                    ]
                }]
            },
            {
                "key": "color",
                "type": "string",
                "label": "Color",
                "required": false,
                "default": "#2196F3",
                "subschema": []
            },
            {
                "key": "disabled",
                "type": "boolean",
                "label": "Disabled",
                "required": false,
                "subschema":[]
            },
            {
                "key": "touchSoundDisabled",
                "type": "boolean",
                "label": "TouchSoundDisabled",
                "required": false,
                "subschema":[]
            }
        ],
        "propsmapping":[
            {
                "key": "title",
                "mapping": ""
            },
            {
                "key": "accessibilityLabel",
                "mapping": ""
            },
            {
                "key": "accessibilityActions",
                "mapping":""
            },
            {
                "key": "color",
                "mapping": ""
            },
            {
                "key": "disabled",
                "mapping": ""
            },
            {
                "key": "touchSoundDisabled",
                "mapping": ""
            }
        ],
        "interactions":[
            {
                "name": "onPress",
                "properties": [
                    
                ]
            },
            {
                "name": "onAccessibilityAction",
                "properties":[

                ]
            }
        ],
        "styles":[]
    },
    {
        "tag": "Image",
        "type": "native",
        "props":[
            {
                "key": "source",
                "type": "file",
                "required": true,
                "label": "Source",
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            },
            {
                "key": "loadingIndicatorSource",
                "type": "file",
                "required": false,
                "label": "LoadingIndicatorSource",
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            
            {
                "key": "resizeMode",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "label": "ResizeMode",
                "subschema":[],
                "options":[
                    {
                        "name": "cover",
                        "value": "cover"
                    },
                    {
                        "name": "contain",
                        "value": "contain"
                    },
                    {
                        "name": "stretch",
                        "value": "stretch"
                    },
                    {
                        "name": "repeat",
                        "value": "repeat"
                    },
                    {
                        "name": "center",
                        "value": "center"
                    }
                ]
            },
            {
                "key": "accessible",
                "label": "Accessible",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "boolean",
                "subschema":[],
                "default": false
            },
            {
                "key": "accessibilityLabel",
                "label": "AccessibilityLabel",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "string",
                "subschema": []
            },
            {
                "key": "alt",
                "label": "Alt",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "string",
                "subschema": []
            },
            {
                "key": "blurRadius",
                "label": "BlurRadius",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "number",
                "subschema": []
            },
            {
                "key": "crossOrigin",
                "label": "CrossOrigin",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "enum",
                "options": [
                    {
                        "name": "anonymous",
                        "value": "anonymous"
                    },
                    {
                        "name": "use-credentials",
                        "value": "use-credentials"
                    }
                ],
                "subschema": []
            }
        ],
        "propsmapping":[
            {
                "key": "source",
                "mapping": ""
            },
            {
                "key": "loadingIndicatorSource",
                "mapping": ""
            },
            {
                "key": "resizeMode",
                "mapping": ""
            },
            {
                "key": "accessible",
                "mapping": ""
            },
            {
                "key": "accessibilityLabel",
                "mapping": ""
            },
            {
                "key": "alt",
                "mapping": ""
            },
            {
                "key": "blurRadius",
                "mapping": ""
            },
            {
                "key": "crossOrigin",
                "mapping": ""
            }
        ],
        "interactions":[],
        "styles":[]
    },
    {
        "tag": "ImageBackGround",
        "type": "native",
        "props": [
            {
                "key": "source",
                "type": "file",
                "required": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "label": "Source",
                "subschema":[]
            },
            {
                "key": "loadingIndicatorSource",
                "type": "file",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "label": "LoadingIndicatorSource",
                "subschema": []
            },
            {
                "key": "resizeMode",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "label": "ResizeMode",
                "subschema":[],
                "options":[
                    {
                        "name": "cover",
                        "value": "cover"
                    },
                    {
                        "name": "contain",
                        "value": "contain"
                    },
                    {
                        "name": "stretch",
                        "value": "stretch"
                    },
                    {
                        "name": "repeat",
                        "value": "repeat"
                    },
                    {
                        "name": "center",
                        "value": "center"
                    }
                ]
            },
            {
                "key": "accessible",
                "label": "Accessible",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "boolean",
                "subschema":[],
                "default": false
            },
            {
                "key": "accessibilityLabel",
                "label": "AccessibilityLabel",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "string",
                "subschema": []
            },
            {
                "key": "alt",
                "label": "Alt",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "string",
                "subschema": []
            },
            {
                "key": "blurRadius",
                "label": "BlurRadius",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "number",
                "subschema": []
            },
            {
                "key": "crossOrigin",
                "label": "CrossOrigin",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "enum",
                "options": [
                    {
                        "name": "anonymous",
                        "value": "anonymous"
                    },
                    {
                        "name": "use-credentials",
                        "value": "use-credentials"
                    }
                ],
                "subschema": []
            }
        ],
        "propsmapping":[
            {
                "key": "source",
                "mapping": ""
            },
            {
                "key": "loadingIndicatorSource",
                "mapping": ""
            },
            {
                "key": "resizeMode",
                "mapping": ""
            },
            {
                "key": "accessible",
                "mapping": ""
            },
            {
                "key": "accessibilityLabel",
                "mapping": ""
            },
            {
                "key": "alt",
                "mapping": ""
            },
            {
                "key": "blurRadius",
                "mapping": ""
            },
            {
                "key": "crossOrigin",
                "mapping": ""
            }
        ],
        "interactions":[],
        "styles":[]
    },
    {
        "tag": "KeyboardAvoidingView",
        "type": "native",
        "props":[
            {
                "key": "behaviour",
                "label": "Behaviour",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[],
                "options":[
                    {
                        "name": "height",
                        "value": "height"
                    },
                    {
                        "name": "position",
                        "value": "position"
                    },
                    {
                        "name": "padding",
                        "value": "padding"
                    }
                ]
            },
            {
                "key": "enabled",
                "type": "boolean",
                "label": "Enabled",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": true
            },
            {
                "key": "keyboardVerticalOffset",
                "type": "number",
                "label": "KeyboardVerticalOffset",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": 0
            }
        ],
        "propsmapping":[
                {
                    "key": "behaviour",
                    "mapping": ""
                },
                {
                    "key": "enabled",
                    "mapping": ""
                },
                {
                    "key": "keyboardVerticalOffset",
                    "mapping": ""
                }
            ],
        "interactions":[],
        "styles":[],
        "childs": []
    },
    {
        "tag": "Modal",
        "type": "native",
        "props": [
            {
                "key": "visible",
                "label": "Visible",
                "required": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "boolean",
                "subschema": [],
            },
            {
                "key": "animationType",
                "label": "AnimationType",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "enum",
                "options":[
                    {
                        "name": "none",
                        "value": "none"
                    },
                    {
                        "name": "slide",
                        "value": "slide"
                    },
                    {
                        "name": "fade",
                        "value": "fade"
                    }
                ],
                "subschema": []
            },
            {
                "key": "hardwareAccelerated",
                "label": "HardwareAccelerated",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "boolean",
                "subschema":[],
                "default": false
            },
            {
                "key": "statusBarTranslucent",
                "label": "StatusBarTranslucent",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "boolean",
                "subschema":[],
                "default": false
            },
            {
                "key": "transparent",
                "label": "Transparent",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "boolean",
                "subschema": [],
                "default": false
            }
        ],
        "propsmapping":[
            {
                "key": "visible",
                "mapping": ""
            },
            {
                "key": "animationType",
                "mapping": ""
            },
            {
                "key": "hardwareAccelerated",
                "mapping": ""
            },
            {
                "key": "statusBarTranslucent",
                "mapping": ""
            },
            {
                "key": "transparent",
                "mapping": ""
            }
        ],
        "interactions": [],
        "styles": [],
        "childs":[]
    },
    {
        "tag": "Pressable",
        "type": "native",
        "props": [
            {
                "key": "android_disableSound",
                "label": "AndroidDisableSound",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "boolean",
                "subschema": [],
                "default": false
            },
            {
                "key": "unstable_pressDelay",
                "label": "UnstablePressDelay",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "number",
                "subschema":[]
            },
            {
                "key": "delayLongPress",
                "label": "DelayLongPress",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "number",
                "subschema": [],
                "default": 5000
            },
            {
                "key": "disabled",
                "label": "Disabled",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "boolean",
                "subschema":[],
                "default": false
            },
            {
                "key": "android_ripple",
                "label": "AndroidRipple",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "object",
                "subschema":[
                    {
                        "key": "color",
                        "label": "Color",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema": [],
                        "type": "string"
                    },
                    {
                        "key": "borderless",
                        "label": "Borderless",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema": [],
                        "type": "boolean"
                    },
                    {
                        "key": "android_ripple",
                        "label": "AndroidRipple",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "type": "number",
                        "subschema": []
                    },
                    {
                        "key": "foreground",
                        "label": "Foreground",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "type": "boolean",
                        "subschema": []
                    }
                ]
            },
            {
                "key": "hitSlop",
                "label": "HitSlop",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "object",
                "subschema":[
                    {
                        "key": "bottom",
                        "label": "Bottom",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "type": "number",
                        "subschema": []
                    },
                    {
                        "key": "top",
                        "label": "Top",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "type": "number",
                        "subschema":[]
                    },
                    {
                        "key": "left",
                        "label": "Left",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "type": "number",
                        "subschema": []
                    },
                    {
                        "key": "right",
                        "label": "Right",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "type": "number",
                        "subschema": []
                    }
                ]
            },
            {
                "key": "pressRetentionOffset",
                "label": "PressRetentionOffset",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "type": "object",
                "subschema":[
                    {
                        "key": "bottom",
                        "label": "Bottom",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "type": "number",
                        "subschema": []
                    },
                    {
                        "key": "top",
                        "label": "Top",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "type": "number",
                        "subschema":[]
                    },
                    {
                        "key": "left",
                        "label": "Left",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "type": "number",
                        "subschema": []
                    },
                    {
                        "key": "right",
                        "label": "Right",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "type": "number",
                        "subschema": []
                    }
                ]
            }
        ],
        "propsmapping": [
            {
                "key": "android_disableSound",
                "mapping": ""
            },
            {
                "key": "unstable_pressDelay",
                "mapping": ""
            },
            {
                "key": "delayLongPress",
                "mapping": ""
            },
            {
                "key": "disabled",
                "mapping": ""
            },
            {
                "key": "android_ripple",
                "mapping":[
                    {
                      "key": "color",
                      "mapping": ""  
                    },
                    {
                        "key": "borderless",
                        "mapping": ""
                    },
                    {
                        "key": "android_ripple",
                        "mapping": ""
                    },
                    {
                        "key": "foreground",
                        "mapping": ""
                    }
                ]
            },
            {
                "key": "hitSlop",
                "mapping":[
                    {
                        "key": "bottom",
                        "mapping": ""
                    },
                    {
                        "key": "top",
                        "mapping": ""
                    },
                    {
                        "key": "left",
                        "mapping": ""
                    },
                    {
                        "key": "right",
                        "mapping": ""
                    }
                ]
            },
            {
                "key": "pressRetentionOffset",
                "mapping":[
                    {
                        "key": "bottom",
                        "mapping": ""
                    },
                    {
                        "key": "top",
                        "mapping": ""
                    },
                    {
                        "key": "left",
                        "mapping": ""
                    },
                    {
                        "key": "right",
                        "mapping": ""
                    }
                ]
            }

        ],
        "interactions": [
            
        ],
        "childs": [],
        "styles": []
    },
    {
        "tag": "RefereshControl",
        "type": "native",
        "props": [
            {
                "key": "refreshing",
                "label": "Refreshing",
                "type": "boolean",
                "required": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "colors",
                "label": "Colors",
                "type": "array",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [
                    {
                        "type": "string"
                    }
                ]
            },
            {
                "key": "enabled",
                "label": "Enabled",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            },
            {
                "key": "progressBackgroundColor",
                "label": "ProgressBackgroundColor",
                "type": "string",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "progressViewOffset",
                "label": "ProgressViewOffset",
                "type": "number",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "size",
                "label": "Size",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "options": [
                    {
                        "name": "default",
                        "value": "default"
                    },
                    {
                        "name": "large",
                        "value": "large"
                    }
                ],
                "subschema": []
            }
        ],
        "propsmapping": [
            {
                "key": "refreshing",
                "mapping": ""
            },
            {
                "key": "colors",
                "mapping": ""
            },
            {
                "key": "enabled",
                "mapping": ""
            },
            {
                "key": "progressBackgroundColor",
                "mapping": ""
            },
            {
                "key": "progressViewOffset",
                "mapping": ""
            },
            {
                "key": "size",
                "mapping": ""
            }
        ],
        "interactions": [],
        "childs": [],
        "styles": []
    },
    {
        "tag": "ScrollView",
        "type": "native",
        "props": [
            {
                "key": "contentOffset",
                "type": "object",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "label": "ContentOffset",
                "subschema":[
                    {
                        "key": "x",
                        "label": "x",
                        "type": "number",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "default": 0
                    },
                    {
                        "key": "y",
                        "label": "y",
                        "type": "number",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema": [],
                        "default": 0
                    }
                ]
            },
            {
                "key": "decelerationRate",
                "label": "DecelerationRate",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "options":[
                    {
                        "name": "fast",
                        "value": "fast"
                    },
                    {
                        "name": "normal",
                        "value": "normal"
                    }
                ],
                "subschema":[]
            },
            {
                "key": "disableIntervalMomentum",
                "label": "DisableIntervalMomentum",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[],
                "default": false
            },
            {
                "key": "disableScrollViewPanResponder",
                "label": "DisableScrollViewPanResponder",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": false
            },
            {
                "key": "endFillColor",
                "label": "EndFillColor",
                "type": "string",
                "subschema": [],
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
            },
            {
                "key": "fadingEdgeLength",
                "label": "FadingEdgeLength",
                "type": "number",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": 0
            },
            {
                "key": "horizontal",
                "label": "Horizontal",
                "type": "boolean",
                "subschema": [],
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "default": false
            },
            {
                "key": "invertStickyHeaders",
                "label": "InvertStickyHeaders",
                "type": "boolean",
                "subschema": [],
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "default": false
            },
            {
                "key": "keyboardDismissMode",
                "label": "KeyboardDismissMode",
                "type": "enum",
                "options":[
                    {
                        "name": "none",
                        "value": "none"
                    },
                    {
                        "name": "on-drag",
                        "value": "on-drag"
                    }
                ],
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "keyboardShouldPersistTaps",
                "label": "KeyboardShouldPersistTaps",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "options": [
                    {
                        "name": "never",
                        "value": "never"
                    },
                    {
                        "name": "always",
                        "value": "always"
                    },
                    {
                        "name": "handled",
                        "value": "handled"
                    }
                ]
            },
            {
                "key": "maintainVisibleContentPosition",
                "label": "MaintainVisibleContentPosition",
                "type": "object",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[
                    {
                        "key": "minIndexForVisible",
                        "label": "MinIndexForVisible",
                        "type": "number",
                        "required": false,
                        "subschema": []
                    },
                    {
                        "key": "autoscrollToTopThreshold",
                        "label": "AutoscrollToTopThreshold",
                        "type": "number",
                        "required": false,
                        "subschema": []
                    }
                ]
            },
            {
                "key": "nestedScrollEnabled",
                "label": "NestedScrollEnabled",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "overScrollMode",
                "label": "OverScrollMode",
                "type": "enum",
                "options": [
                    {
                        "name": "auto",
                        "value": "auto"
                    },
                    {
                        "name": "always",
                        "value": "always"
                    },
                    {
                        "name": "never",
                        "value": "never"
                    }
                ],
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "default": "auto",
                "subschema": []
            },
            {
                "key": "pagingEnabled",
                "label": "PagingEnabled",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": false
            },
            {
                "key": "persistentScrollbar",
                "label": "PersistentScrollbar",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": false
            },
            {
                "key": "removeClippedSubviews",
                "label": "RemoveClippedSubviews",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": false
            },
            {
                "key": "scrollEnabled",
                "label": "ScrollEnabled",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": true
            },
            {
                "key": "scrollEventThrottle",
                "label": "ScrollEventThrottle",
                "type": "number",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": 0
            },
            {
                "key": "scrollPerfTag",
                "label": "ScrollPerfTag",
                "type": "string",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "showsHorizontalScrollIndicator",
                "label": "ShowsHorizontalScrollIndicator",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": true
            },
            {
                "key": "showsVerticalScrollIndicator",
                "label": "ShowsVerticalScrollIndicator",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": true
            },
            {
                "key": "snapToEnd",
                "label": "SnapToEnd",
                "type": "boolean",
                "required": false,
                "subschema": [],
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "default": true
            },
            {
                "key": "snapToInterval",
                "label": "SnapToInterval",
                "type": "number",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "snapToOffsets",
                "label": "SnapToOffsets",
                "type": "array",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[
                    {
                        "type": "number"
                    }
                ]
            },
            {
                "key": "snapToStart",
                "label": "SnapToStart",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": true
            },
            {
                "key": "stickyHeaderHiddenOnScroll",
                "label": "StickyHeaderHiddenOnScroll",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "default": false
            },
            {
                "key": "stickyHeaderIndices",
                "label": "StickyHeaderIndices",
                "type": "array",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [
                    {
                        "type": "number"
                    }
                ]
            }
        ],
        "propsmapping": [
            {
                "key": "contentOffset",
                "mapping": [
                    {
                        "key": "x",
                        "mapping": ""
                    },
                    {
                        "key": "y",
                        "mapping": ""
                    }
                ]
            },
            {
                "key": "decelerationRate",
                "mapping": ""
            },
            {
                "key": "disableIntervalMomentum",
                "mapping": ""
            },
            {
                "key": "disableScrollViewPanResponder",
                "mapping": ""
            },
            {
                "key": "endFillColor",
                "mapping": ""
            },
            {
                "key": "fadingEdgeLength",
                "mapping": ""
            },
            {
                "key": "horizontal",
                "mapping": ""
            },
            {
                "key": "invertStickyHeaders",
                "mapping": ""
            },
            {
                "key": "keyboardDismissMode",
                "mapping": ""
            },
            {
                "key": "keyboardShouldPersistTaps",
                "mapping": ""
            },
            {
                "key": "maintainVisibleContentPosition",
                "mapping": [
                    {
                        "key": "minIndexForVisible",
                        "mapping": ""
                    },
                    {
                        "key": "autoscrollToTopThreshold",
                        "mapping": ""
                    }
                ]
            },
            {
                "key": "nestedScrollEnabled",
                "mapping": ""
            },
            {
                "key": "overScrollMode",
                "mapping": ""
            },
            {
                "key": "pagingEnabled",
                "mapping": ""
            },
            {
                "key": "persistentScrollbar",
                "mapping": ""
            },
            {
                "key": "removeClippedSubviews",
                "mapping": ""
            },
            {
                "key": "scrollEnabled",
                "mapping": ""
            },
            {
                "key": "scrollEventThrottle",
                "mapping": ""
            },
            {
                "key": "scrollPerfTag",
                "mapping": ""
            },
            {
                "key": "showsHorizontalScrollIndicator",
                "mapping": ""
            },
            {
                "key": "showsVerticalScrollIndicator",
                "mapping": ""   
            },
            {
                "key": "snapToEnd",
                "mapping": ""
            },
            {
                "key": "snapToInterval",
                "mapping": ""
            },
            {
                "key": "snapToOffsets",
                "mapping": ""   
            },
            {
                "key": "snapToStart",
                "mapping": ""
            },
            {
                "key": "stickyHeaderHiddenOnScroll",
                "mapping": ""
            },
            {
                "key": "stickyHeaderIndices",
                "mapping": ""
            }
        ],
        "interactions": [
            {
                "name": "onScroll",
                "properties":[
                    {
                        "key": "nativeEvent",
                        "label": "NativeEvent",
                        "type": "object",
                        "required": false,
                        "subschema":[
                            {
                                "key": "contentInset",
                                "label": "ContentInset",
                                "type": "object",
                                "required": false,
                                "subschema":[
                                    {
                                        "key": "bottom",
                                        "type": "number",
                                        "label": "Bottom",
                                        "required": false,
                                        "subschema": []
                                    },
                                    {
                                        "key": "left",
                                        "type": "number",
                                        "label": "Left",
                                        "required": false,
                                        "subschema":[]
                                    },
                                    {
                                        "key": "right",
                                        "type": "number",
                                        "label": "Right",
                                        "required": false,
                                        "subschema": []
                                    },
                                    {
                                        "key": "top",
                                        "type": "number",
                                        "label": "Top",
                                        "required": false,
                                        "subschema": []
                                    }
                                ]
                            },
                            {
                                "key": "contentOffset",
                                "label": "ContentOffset",
                                "type": "object",
                                "subschema": [
                                    {
                                        "key": "x",
                                        "label": "X",
                                        "type": "number",
                                        "required": false,
                                        "subschema": []
                                    },
                                    {
                                        "key": "y",
                                        "label": "Y",
                                        "type": "number",
                                        "required": false,
                                        "subschema": []
                                    }
                                ]
                            },
                            {
                                "key": "contentSize",
                                "label": "ContentSize",
                                "type": "object",
                                "required": false,
                                "subschema":[
                                    {
                                        "key": "height",
                                        "label": "Height",
                                        "type": "number",
                                        "required": false,
                                        "subschema": []
                                    },
                                    {
                                        "key": "width",
                                        "label": "Width",
                                        "type": "number",
                                        "required": false,
                                        "subschema": []
                                    }
                                ]
                            },
                            {
                                "key": "layoutMeasurement",
                                "label": "LayoutMeasurement",
                                "type": "object",
                                "required": false,
                                "subschema":[
                                    {
                                        "key": "height",
                                        "label": "Height",
                                        "type": "number",
                                        "required": false,
                                        "subschema": []
                                    },
                                    {
                                        "key": "width",
                                        "label": "Width",
                                        "type": "number",
                                        "required": false,
                                        "subschema": []
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "onContentSizeChange",
                "properties":[]
            },
            {
                "name": "onMomentumScrollBegin",
                "properties": []
            },
            {
                "name": "onMomentumScrollEnd",
                "properties": []
            }
        ],
        "instanceMethods":[
            {
                "action": "flashScrollIndicators",
                "inputschema":[],
                "inputschemamapping": []
            },
            {
                "action": "scrollTo",
                "inputschema":[
                    {
                        "key": "x",
                        "label": "X",
                        "required": true,
                        "type": "number",
                        "subschema": []
                    },
                    {
                        "key": "y",
                        "label": "Y",
                        "required": true,
                        "type": "number",
                        "subschema": []
                    },
                    {
                        "key": "animated",
                        "label": "Animated",
                        "required": true,
                        "type": "boolean",
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "x",
                        "mapping": ""
                    },
                    {
                        "key": "y",
                        "mapping": ""
                    },
                    {
                        "key": "animated",
                        "mapping": ""
                    }
                ]
            },
            {
                "action": "scrollToEnd",
                "inputschema":[
                    {
                        "key": "animated",
                        "label": "Animated",
                        "required": true,
                        "type": "boolean",
                        "subschema": []
                    }
                ]
            }
        ],
        "childs": [],
        "styles": []
    },
    {
        "tag": "SectionList",
        "type": "native",
        "props": [],
        "propsmapping": [],
        "interactions": [],
        "childs": [],
        "styles": []
    },
    {
        "tag": "StatusBar",
        "type": "native",
        "props": [
            {
                "key": "animated",
                "label": "Animated",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "backgroundColor",
                "label": "BackgroundColor",
                "type": "string",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "barStyle",
                "label": "BarStyle",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "options": [
                    {
                        "name": "default",
                        "value": "default" 
                    },
                    {
                        "name": "light-content",
                        "value": "light-content"
                    },
                    {
                        "name": "dark-content",
                        "value": "dark-content "
                    }
                ]
            },
            {
                "key": "hidden",
                "label": "Hidden",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "translucent",
                "label": "translucent",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            }
        ],
        "propsmapping": [
            {
                "key": "animated",
                "mapping": ""
            },
            {
                "key": "backgroundColor",
                "mapping": ""
            },
            {
                "key": "barStyle",
                "mapping": ""
            },
            {
                "key": "hidden",
                "mapping": ""
            },
            {
                "key": "translucent",
                "mapping": ""
            }
        ],
        "childs": [],
        "interactions": [],
        "styles": []
    },
    {
        "tag": "Switch",
        "type": "native",
        "props": [],
        "propsmapping": [],
        "interactions": [],
        "styles": []
    },
    {
        "tag": "Text",
        "type": "native",
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ],
        "props": [],
        "propsmapping": [],
        "interactions": [],
        "styles": []
    },
    {
        "tag": "TextInput",
        "type": "native",
        "props": [],
        "propsmapping": [],
        "interactions": [],
        "styles": []
    },
    {
        "tag": "View",
        "type": "native",
        "props": [],
        "propsmapping": [],
        "interactions": [],
        "styles": [],
        "childs": []
    },
    {
        "tag": "PagerView",
        "type": "native",
        "props": [
            {
                "key": "initialPage",
                "label": "InitialPage",
                "type": "number",
                "required": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "scrollEnabled",
                "label": "ScrollEnabled",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "pageMargin",
                "label": "PageMargin",
                "type": "number",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            },
            {
                "key": "keyboardDismissMode",
                "label": "KeyboardDismissMode",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "options": [
                    {
                        "name": "none",
                        "value": "none"
                    },
                    {
                        "name": "on-drag",
                        "value": "on-drag"
                    }
                ],
                "subschema":[]
            },
            {
                "key": "orientation",
                "label": "Orientation",
                "type": "enum",
                "required": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "options":[
                    {
                        "name": "vertical",
                        "value": "vertical"
                    },
                    {
                        "name": "horizontal",
                        "value": "horizontal"
                    }
                ],
                "subschema": []
            },
            {
                "key": "overScrollMode",
                "label": "OverScrollMode",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "options": [
                    {
                        "name": "auto",
                        "value": "auto"
                    },
                    {
                        "name": "always",
                        "value": "always"
                    },
                    {
                        "name": "never",
                        "value": "never"
                    }
                ],
                "subschema": []
            },
            {
                "key": "offscreenPageLimit",
                "label": "OffscreenPageLimit",
                "type": "number",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            },
            {
                "key": "overdrag",
                "label": "Overdrag",
                "type": "boolean",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema":[]
            },
            {
                "key": "layoutDirection",
                "label": "layoutDirection",
                "type": "enum",
                "required": false,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": [],
                "options":[
                    {
                        "name": "rtl",
                        "value": "rtl"
                    },
                    {
                        "name": "ltr",
                        "value": "ltr"
                    },
                    {
                        "name": "locale",
                        "value": "locale"
                    }
                ]
            }
        ],
        "propsmapping": [
            {
                "key": "initialPage",
                "mapping": ""
            },
            {
                "key": "scrollEnabled",
                "mapping": ""
            },
            {
                "key": "pageMargin",
                "mapping": ""
            },
            {
                "key": "keyboardDismissMode",
                "mapping": ""
            },
            {
                "key": "orientation",
                "mapping": ""
            },
            {
                "key": "overScrollMode",
                "mapping": ""
            },
            {
                "key": "offscreenPageLimit",
                "mapping": ""
            },
            {
                "key": "overdrag",
                "mapping": ""
            },
            {
                "key": "layoutDirection",
                "mapping": ""
            }
        ],
        "interactions": [
            {
                "name": "onPageScroll",
                "properties":[
                    {
                        "key": "position",
                        "type": "number",
                        "subschema": []
                    },
                    {
                        "key": "offset",
                        "type": "number",
                        "subschema": []
                    }
                ]
            },
            {
                "name": "onPageScrollStateChanged",
                "properties": [
                    {
                        "key": "pageScrollState",
                        "type": "string"
                    }
                ]
            },
            {
                "name": "onPageSelected",
                "properties": [
                    {
                        "key": "position",
                        "type": "number"
                    }
                ]
            }
        ],
        "styles": []
    },
    {
        "tag": "WebView",
        "type": "native",
        "props": [
            {
                "key": "source",
                "label": "Source",
                "type": "string",
                "required": true,
                "fillallowed": true,
                "guided": false,
                "guidefinished": true,
                "subschema": []
            }
        ],
        "propsmapping": [
            {
                "key": "source",
                "mapping": ""
            }
        ],
        "interactions": [],
        "styles": []
    },
    {
        "tag": "DrawerLayout",
        "type": "native",
        "props": [],
        "propsmapping": [],
        "interactions": [],
        "styles": []
    }

]

export const ToilerComponents = [
    {
        "tag": "markdown",
        "elemtype": "toiler",
        "innerhtml":[{
            "key": "value",
            "label": "Value",
            "type": "string",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema":[]
        }],
        "innerhtmlmapping":[
            {
                "key": "value",
                "mapping": ""
            }
        ]
    }
]
