import { toilerUrl } from "./env";

function HttpError(status,error){
    this.response = {
        "data":error
    };
    this.status = status;
}


export const getWebsiteApi = async(payload)=>{
    try{
        let res = await fetch(toilerUrl+"websites/getclient/",{
            "method": "POST",
            "headers":{
                "Content-type": "application/json"
            },
            "body": JSON.stringify(payload)
            }
        );
        if(!res.ok){
            let err = await res.json();
            throw new HttpError(500,err);
        }
        return res.json();
    }catch(err){
        throw err;
    }
}








