import * as utils from "../utils";

export const executeAction = async (stage, pipeline, key)=>{
    if(stage.action=="getkey"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let clientid = localStorage.getItem("clientid");
        let val = localStorage.getItem(clientid+"___"+inputval.key);
        return {
            "result": val
        }
    }else if(stage.action=="setkey"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let clientid = localStorage.getItem("clientid");
        let key = clientid+"__"+inputval.key;
        let val = inputval.value;
        localStorage.setItem(key, val)
        return {
            "ack": true
        }
    }else if(stage.action=="clear"){
        let clientid = localStorage.getItem("clientid");
        for(let i=0; i< localStorage.length; i++){
            let key = localStorage.key(i);
            if(key.startsWith(clientid+"__")){
                localStorage.removeItem(key);
            }
        }
        return {
            "ack": true
        }
    }
}